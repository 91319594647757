import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAgencyAuth } from "../../context/AgencyAuthContext";
import Loading from "../common/Loading";
import OfficerInvite from "./OfficerInvite";
import OfficerTable from "./OfficerTable";
import Button from "../common/Button";
import { toastOptions } from "../common/toastOptions";
import { Cog8ToothIcon } from "@heroicons/react/24/solid";

// Stats card component for dashboard
const StatsCard = ({
  title,
  value,
}: {
  title: string;
  value: number | string;
}) => {
  return (
    <div className="p-6 card border border-gray-200 bg-white">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-sm font-medium text-gray-600">{title}</p>
          <p className="text-2xl font-bold text-gray-900 mt-1">{value}</p>
        </div>
      </div>
    </div>
  );
};

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY ||
    "pk_live_51PydONG4bf6K0RLmdfEJzwpzVpDCNNdt0HjC5sn5QokyLJij6TflI306Xoi69SSqHORZ9cPnt3xw2leQx9rrYb0B00nka5yr7n"
);

function Dashboard() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { agency } = useAgencyAuth();
  const [invitations, setInvitations] = useState<[]>([]);
  const [seatsToPurchase, setSeatsToPurchase] = useState(1);
  const [isLoadingPortal, setIsLoadingPortal] = useState(false);

  // Define price per seat - this would ideally come from your backend or environment
  const PRICE_PER_SEAT = 359.88; // $25 per seat per month

  // Calculate total price in real time as seats change
  const totalPrice = useMemo(() => {
    return (seatsToPurchase * PRICE_PER_SEAT)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }, [seatsToPurchase]);

  const location = useLocation();
  const path = location.pathname.split("/")[1];

  const fetchInvitations = async () => {
    try {
      const { data } = await axios.get("/api/agency/invitations");

      // Clean and deduplicate the data
      const uniqueInvitations = data.reduce((acc: any[], curr: any) => {
        const existingIndex = acc.findIndex(
          (item) => item.email === curr.email
        );
        if (existingIndex === -1) {
          acc.push(curr);
        } else if (curr.createdAt && !acc[existingIndex].createdAt) {
          acc[existingIndex] = curr;
        }
        return acc;
      }, []);

      setInvitations(uniqueInvitations);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching invitations:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (agency) {
      fetchInvitations();
    }
  }, [agency]);

  const handleInvite = async (emails: string[]) => {
    try {
      // Immediately refresh the invitations list after successful invite
      const { data } = await axios.post("/api/agency/invite", { emails });

      // Show success for successful invites
      data.results.forEach((result) => {
        if (result.status === "existing") {
          toast.error(`${result.email} already belongs to an agency`);
        }
      });
      fetchInvitations();
    } catch (error) {
      console.error("Error sending invitations:", error);
    }
  };

  const handlePurchaseSeats = async () => {
    try {
      const { data } = await axios.post("/api/agency/purchase-seats", {
        seats: seatsToPurchase,
      });

      const stripe = await stripePromise;

      if (stripe) {
        const { error } = await stripe.redirectToCheckout({
          sessionId: data.sessionId,
        });

        if (error) {
          console.error("Error:", error);
        }
      }
    } catch (error) {
      console.error("Error purchasing seats:", error);
    }
  };

  const handleManageSubscription = async () => {
    try {
      setIsLoadingPortal(true);
      const response = await axios.post(
        "/api/agency/portal",
        {},
        {
          withCredentials: true,
        }
      );

      const { url } = response.data;
      window.location.href = url;
    } catch (error) {
      console.error("Error accessing subscription portal:", error);
    } finally {
      setIsLoadingPortal(false);
    }
  };

  const handleLogout = async () => {
    try {
      await axios.post("/api/auth/logout");
      navigate("/agency/login");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  const hasSeats = (agency?.max_users || 0) > 0;

  // Calculate statistics for dashboard cards
  const totalSeats = agency?.max_users || 0;
  // Count active seats as users who have accepted (these are actual users with agency_id set)
  const activeSeats = invitations.filter(
    (inv: any) => inv.accepted === true
  ).length;
  // Count pending invites (these are invitations that haven't been accepted yet)
  const pendingInvites = invitations.filter(
    (inv: any) => inv.accepted === false
  ).length;
  // Available seats should be total seats minus active seats (not all invitations)
  const availableSeats = Math.max(0, totalSeats - activeSeats - pendingInvites);

  return (
    <>
      <div className="min-h-screen bg-gray-50">
        <div className="p-8 max-w-6xl mx-auto">
          <div className="mb-8 flex items-center justify-between">
            <div>
              <h1 className="text-3xl font-bold text-gray-900 flex items-center gap-1">
                Agency Settings:{" "}
                <span className="text-blue-700">{agency?.name}</span>
                <div className="dropdown dropdown-bottom">
                  <div>
                    <Cog8ToothIcon
                      tabIndex={0}
                      className="mt-1 size-8 text-gray-500 opacity-50 hover:opacity-80 transition-opacity cursor-pointer"
                    />
                  </div>
                  <ul
                    tabIndex={0}
                    className="dropdown-content menu bg-base-100 rounded-lg z-[1] w-52 p-2 shadow mt-2"
                  >
                    <li>
                      <button
                        onClick={handleLogout}
                        className="hover:bg-gray-100 w-full text-left"
                      >
                        Log out
                      </button>
                    </li>
                  </ul>
                </div>
              </h1>
              <p className="mt-2 text-gray-500 font-semibold">
                Manage and view your officers
              </p>
            </div>
          </div>

          {/* Stats Cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
            <StatsCard title="Total Seats" value={totalSeats} />
            <StatsCard title="Active Seats" value={activeSeats} />
            <StatsCard title="Available Seats" value={availableSeats} />
            <StatsCard title="Pending Invites" value={pendingInvites} />
          </div>

          {!hasSeats ? (
            <div className="p-6 card border border-gray-200 bg-white">
              <h2 className="card-title">Manage Seats</h2>
              <p className="text-gray-600 mb-4">
                Your agency doesn't have any seats available. Purchase seats to
                start inviting officers to your agency.
              </p>

              <div className="bg-gray-50 p-8 relative rounded-md">
                <div className="flex flex-col gap-4">
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="seats"
                      className="text-gray-700 font-medium"
                    >
                      Number of seats:
                    </label>
                    <div className="flex items-center gap-2">
                      <button
                        onClick={() =>
                          setSeatsToPurchase(Math.max(1, seatsToPurchase - 1))
                        }
                        className="px-3 py-1 border rounded bg-white hover:bg-gray-100"
                      >
                        -
                      </button>
                      <input
                        id="seats"
                        type="number"
                        min="1"
                        value={seatsToPurchase}
                        onChange={(e) =>
                          setSeatsToPurchase(
                            Math.max(1, parseInt(e.target.value) || 1)
                          )
                        }
                        className="border rounded px-3 py-2 w-24 text-center [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                      />
                      <button
                        onClick={() => setSeatsToPurchase(seatsToPurchase + 1)}
                        className="px-3 py-1 border rounded bg-white hover:bg-gray-100"
                      >
                        +
                      </button>
                    </div>
                  </div>

                  <div className="bg-white p-4 border border-gray-200 rounded-md">
                    <div className="flex justify-between items-center mb-2">
                      <span className="text-gray-600">
                        {seatsToPurchase}{" "}
                        {seatsToPurchase === 1 ? "seat" : "seats"} × $
                        {PRICE_PER_SEAT} / year
                      </span>
                      <span className="text-gray-600">
                        ${totalPrice} / year
                      </span>
                    </div>
                    <div className="border-t pt-2 mt-2 flex justify-between items-center">
                      <span className="font-medium">Total</span>
                      <span className="font-bold text-lg">
                        ${totalPrice} / year
                      </span>
                    </div>
                  </div>

                  <Button
                    onClick={handlePurchaseSeats}
                    variant="primary"
                    className="w-full mt-2"
                  >
                    Purchase Seats
                  </Button>
                </div>
              </div>
              <p className="text-gray-500 text-sm mt-4 text-center">
                Each seat allows one officer to access the platform. Please
                contact{" "}
                <a href="mailto:support@policenarratives.ai">
                  support@policenarratives.ai
                </a>{" "}
                if you have any questions.
              </p>
            </div>
          ) : (
            <>
              <div className="mt-8">
                <OfficerInvite
                  maxOfficers={agency?.max_users || 0}
                  currentOfficers={activeSeats}
                  availableSeats={availableSeats}
                  onInvite={handleInvite}
                />
              </div>
              <OfficerTable
                officers={invitations}
                onUpdate={fetchInvitations}
              />
            </>
          )}
        </div>
      </div>
      <Toaster position="bottom-center" toastOptions={toastOptions} />
    </>
  );
}

export default Dashboard;

import { loadStripe } from "@stripe/stripe-js";
import { startCase } from "lodash";
import axios, { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { FaApple } from "react-icons/fa";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import bgImg from "../pnai-bg.jpg";
import { ReactComponent as Logo } from "../police-narratives-ai.svg";
import Button from "./common/Button";
import Input from "./common/Input";
import Loading from "./common/Loading";
import { SubscriptionStatusType } from "../db/schema";
import AppleLogin from "react-apple-login";

// Add these utility functions at the top level
const getPriceId = (searchParams: URLSearchParams) =>
  searchParams.get("priceId");
const getRedirectTo = (searchParams: URLSearchParams) =>
  searchParams.get("redirectto");
const appendSearchParams = (path: string, searchParams: URLSearchParams) =>
  `${path}${searchParams.size ? `?${searchParams.toString()}` : ""}`;

// Move outside of Login component

function Login() {
  const [email, setEmail] = useState<string>(process.env.REACT_APP_EMAIL || "");
  const [password, setPassword] = useState<string>(
    process.env.REACT_APP_PASSWORD || ""
  );
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [errorState, setErrorState] = useState<boolean>(false);
  const navigate = useNavigate();
  const { setIsAuthenticated, setUser, refreshSubscriptionStatus } = useAuth();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [isInvitation, setIsInvitation] = useState(false);
  const [department, setDepartment] = useState<string>("");

  useEffect(() => {
    // Check for invitation parameters
    const emailParam = searchParams.get("email");
    const tokenParam = searchParams.get("token");
    const departmentParam = searchParams.get("department");

    if (emailParam && departmentParam && tokenParam) {
      setIsInvitation(true);
      setEmail(emailParam.trim());
      setDepartment(startCase(departmentParam));
    }
  }, [searchParams]);

  useEffect(() => {
    // Check authentication status when component mounts
    const checkAuthStatus = async () => {
      setLoading(true);
      try {
        const response = await axios.get("/api/auth/user");
        if (response.data.user && response.data.user.type === "user") {
          // Add type check
          setIsAuthenticated(true);
          setUser(response.data.user);
          const redirectTo = getRedirectTo(searchParams);
          if (redirectTo) {
            navigate(`/${redirectTo}`);
          } else {
            navigate("/cases");
          }
        } else {
          setIsAuthenticated(false);
          setUser(null);
        }
      } catch (error) {
        // User is not authenticated, stay on login page
        setIsAuthenticated(false);
        setUser(null);
      } finally {
        setLoading(false);
      }
    };

    checkAuthStatus();
  }, [navigate, setIsAuthenticated, setUser, searchParams]);

  const validateForm = () => {
    let newErrors: {
      email?: string;
      password?: string;
    } = {};

    // Email validation
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email is invalid";
    }

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{12,}$/;

    // Password validation
    if (!password) {
      newErrors.password = "Password is required";
    } else if (!passwordRegex.test(password)) {
      newErrors.password =
        "Password must be at least 12 characters long and include a combination of uppercase and lowercase letters, numbers, and symbols";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        const response = await axios.post("/api/auth/login", {
          email,
          password,
        });

        // Check if the user is an agency by looking for max_users property
        if ("max_users" in response.data.user) {
          // If it's an agency login, redirect to agency login page
          navigate("/agency/login");
          return;
        }

        setIsAuthenticated(true);
        setUser(response.data.user);

        // Check for agency invitation parameters
        const emailParam = searchParams.get("email");
        const tokenParam = searchParams.get("token");
        const departmentParam = searchParams.get("department");

        if (emailParam && departmentParam && tokenParam) {
          try {
            // Accept the invitation with token validation
            const inviteResponse = await axios.post(
              "/api/agency/accept-invitation",
              {
                email: emailParam.trim(),
                userId: response.data.user.id,
                token: tokenParam,
              }
            );

            if (inviteResponse.data.success) {
              setUser(inviteResponse.data.user);
            }
          } catch (inviteError) {
            console.error("Error accepting invitation:", inviteError);
            setErrorState(true);
            setErrors({
              overall: "Invalid or expired invitation link.",
            });
            // Explicitly log out the user
            setIsAuthenticated(false);
            setUser(null);
            await axios.post("/api/auth/logout");
            setLoading(false);
            return;
          }
        }

        // Regular user flow continues here...
        const currentSubscriptionStatus = await refreshSubscriptionStatus();

        // Rest of your existing subscription and redirect logic...
        const redirectTo = getRedirectTo(searchParams);
        const priceId = getPriceId(searchParams);

        console.log("currentSubscriptionStatus");
        console.log(currentSubscriptionStatus);

        // Check subscription status using the returned value
        if (
          currentSubscriptionStatus !== SubscriptionStatusType.enum.active &&
          currentSubscriptionStatus !== SubscriptionStatusType.enum.trialing
        ) {
          navigate("/subscription");
          return;
        }

        if (redirectTo) {
          navigate(`/${redirectTo}`);
        } else if (priceId) {
          const stripePromise = loadStripe(
            process.env.REACT_APP_STRIPE_PUBLIC_KEY ||
              "pk_live_51PydONG4bf6K0RLmdfEJzwpzVpDCNNdt0HjC5sn5QokyLJij6TflI306Xoi69SSqHORZ9cPnt3xw2leQx9rrYb0B00nka5yr7n"
          );
          const stripe = await stripePromise;
          if (!stripe) throw new Error("Stripe failed to initialize");
          const checkoutResponse = await axios.post(
            "/api/create-checkout-session",
            { priceId },
            { withCredentials: true }
          );

          // Redirect to checkout
          const result = await stripe.redirectToCheckout({
            sessionId: checkoutResponse.data.sessionId,
          });

          if (result.error) {
            throw new Error(result.error.message);
          }
          return; // Stop here and don't proceed to other redirects
        } else {
          navigate("/cases");
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setErrorState(true);
          const axiosError = error as AxiosError<{ error: string }>;
          if (axiosError.response) {
            console.error(axiosError.response.data.error);
            console.log(error);
            setErrors({
              overall: "Incorrect login credentials.",
            });
          }
        }
      } finally {
        setLoading(false);
      }
    } else {
      setErrorState(true);
    }
  };

  const handleGoogleLogin = () => {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/api/auth/google`;
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      <div
        className="hidden md:flex relative items-center justify-center bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${bgImg})` }}
      >
        <div className="absolute inset-0 bg-blue-50/70"></div>
        <div className="w-3/5 z-10">
          <Logo className="w-full h-auto" />
        </div>
      </div>
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="bg-white p-8 rounded-xl shadow-md w-full max-w-sm">
          {isInvitation ? (
            <>
              <h1 className="text-3xl mb-4 font-semibold">Accept Invitation</h1>
              <p className="mb-3 text-xs text-gray-500">
                You've been invited to join {department} with your existing
                account. Please login to activate your subscription.
              </p>
            </>
          ) : (
            <h1 className="text-3xl mb-4 font-semibold">Login</h1>
          )}

          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <p className="font-semibold pb-1 text-sm">Email</p>
              <Input
                type="email"
                id="email"
                value={email}
                placeholder="Email address"
                onChange={(e) => {
                  errorState && setErrorState(false);
                  setEmail(e.target.value);
                }}
                disabled={isInvitation}
                className={`input w-full ${errorState && "border-red-500"} ${
                  isInvitation && "bg-gray-100"
                }`}
              />
              {errors.email && errorState && (
                <p className="mt-1 text-xs text-red-500">{errors.email}</p>
              )}
            </div>
            <div>
              <p className="font-semibold pb-1 text-sm">Password</p>
              <Input
                type="password"
                id="password"
                value={password}
                placeholder="Password"
                onChange={(e) => {
                  errorState && setErrorState(false);
                  setPassword(e.target.value);
                }}
                className={`input w-full max-w-xs ${
                  errorState && "border-red-500"
                }`}
              />
              {errors.password && errorState && (
                <p className="mt-1 text-xs text-red-500">{errors.password}</p>
              )}
              {errors.overall && errorState && (
                <p className="mt-1 text-xs text-red-500">{errors.overall}</p>
              )}
            </div>
            <div>
              <Button className="w-full" variant="primary" type="submit">
                {isInvitation ? "Accept Invitation" : "Login"}
              </Button>
            </div>
          </form>

          {!isInvitation && (
            <>
              <div className="relative my-4">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">Or</span>
                </div>
              </div>

              <Button
                onClick={handleGoogleLogin}
                variant="outline"
                className="w-full mb-2"
              >
                <FcGoogle className="w-5 h-5" />
                <span>Continue with Google</span>
              </Button>
              <AppleLogin
                clientId="ai.app.policenarratives"
                redirectURI="https://app.policenarratives.ai/api/auth/apple/callback"
                scope="name email"
                responseType="code"
                responseMode="query"
                render={(renderProps) => (
                  <Button
                    onClick={renderProps.onClick}
                    variant="outline"
                    className="w-full mb-2"
                    type="button"
                  >
                    <FaApple className="w-5 h-5" />
                    <span>Continue with Apple</span>
                  </Button>
                )}
              />
              <div className="flex justify-center">
                <p className="font-semibold text-sm text-gray-500">
                  Don't have an account?{" "}
                  <Link to={appendSearchParams("/signup", searchParams)}>
                    Sign up
                  </Link>
                </p>
              </div>
              <div className="flex justify-center mt-2">
                <p className="font-normal text-xs text-gray-500">
                  Switch to{" "}
                  <Link to={appendSearchParams("/agency/login", searchParams)}>
                    Agency Admin view
                  </Link>
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Login;

import { useState, useRef, useEffect } from "react";
import { Officer } from "./officer";
import moment from "moment";
import Button from "../common/Button";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { toast } from "react-hot-toast";

interface OfficerTableProps {
  officers: Officer[];
  onUpdate?: () => void; // Add callback to refresh the list
}

const OfficerTable: React.FC<OfficerTableProps> = ({ officers, onUpdate }) => {
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);
  const [showConfirmId, setShowConfirmId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<Record<string, boolean>>({});
  const dropdownRef = useRef<HTMLDivElement>(null);

  const formatDate = (dateString: string | undefined) => {
    if (!dateString) return "N/A";
    // Parse the UTC date string and format in local time
    return moment.utc(dateString).local().fromNow();
  };

  // Filter members by checking either createdAt or a true accepted flag
  const members = officers.filter(
    (officer) => officer.accepted && !officer.deleted_at
  );

  const invitees = officers.filter(
    (officer) => !officer.accepted && !officer.deleted_at
  );

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        event.target instanceof Element &&
        !event.target.closest(".dropdown")
      ) {
        setOpenDropdownId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleResendInvite = async (id: string) => {
    setIsLoading({ ...isLoading, [id]: true });
    try {
      const officer = officers.find((o) => o.id === id);
      if (!officer) return;

      await axios.post("/api/agency/resend-invite", {
        email: officer.email,
      });

      toast.success(`Invitation resent to ${officer.email}`);
      setOpenDropdownId(null);
      if (onUpdate) onUpdate();
    } catch (error) {
      console.error("Error resending invite:", error);
      toast.error("Failed to resend invite. Please try again.");
    } finally {
      setIsLoading({ ...isLoading, [id]: false });
    }
  };

  const handleCancelInvite = async (id: string, email: string) => {
    setIsLoading({ ...isLoading, [id]: true });
    try {
      const response = await fetch("/api/agency/remove-member", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error("Failed to cancel invitation");
      }

      toast.success(`Invitation cancelled for ${email}`);
      if (onUpdate) {
        onUpdate();
      }
    } catch (error) {
      console.error("Error canceling invitation:", error);
      toast.error("Failed to cancel invitation. Please try again.");
    } finally {
      setShowConfirmId(null);
      setIsLoading({ ...isLoading, [id]: false });
    }
  };

  const handleRemoveMember = async (id: string) => {
    setIsLoading({ ...isLoading, [id]: true });
    try {
      const officer = officers.find((o) => o.id === id);
      const response = await fetch("/api/agency/remove-member", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId: id }),
      });

      if (!response.ok) {
        throw new Error("Failed to remove member");
      }

      toast.success(`Successfully removed ${officer?.email}`);
      if (onUpdate) {
        onUpdate();
      }
    } catch (error) {
      console.error("Error removing member:", error);
      toast.error("Failed to remove member. Please try again.");
    } finally {
      setShowConfirmId(null);
      setIsLoading({ ...isLoading, [id]: false });
    }
  };

  const toggleDropdown = (id: string) => {
    setOpenDropdownId(openDropdownId === id ? null : id);
  };

  return (
    <div className="mt-8 card card-body border border-gray-200 bg-white">
      {/* Members Section */}
      <div className="mb-8">
        <div className="overflow-visible">
          <h2 className="card-title">Members ({members.length})</h2>
          <table className="w-full border-collapse mt-2">
            <thead className="bg-gray-50">
              <tr>
                <th className="p-2 w-1/3 text-left text-sm font-semibold text-gray-500">
                  Email
                </th>
                <th className="p-2 w-1/3 text-left text-sm font-semibold text-gray-500">
                  Joined
                </th>
                <th className="p-2 w-1/3 text-left text-sm font-semibold text-gray-500">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {members.length > 0 ? (
                members.map((officer) => (
                  <tr key={officer.id} className="hover:bg-gray-50">
                    <td className="p-2 w-1/3 whitespace-nowrap text-gray-500 font-semibold text-sm">
                      {officer.email}
                    </td>
                    <td className="p-2 w-1/3 whitespace-nowrap text-gray-500 text-sm">
                      {officer.createdAt
                        ? formatDate(officer.createdAt)
                        : "N/A"}
                    </td>
                    <td className="p-2 w-1/3 whitespace-nowrap relative">
                      {showConfirmId === officer.id ? (
                        <div className="flex space-x-2">
                          <Button
                            variant="danger"
                            size="xs"
                            className="text-xs py-1"
                            onClick={() => handleRemoveMember(officer.id)}
                            disabled={isLoading[officer.id]}
                          >
                            {isLoading[officer.id] ? "Removing..." : "Confirm"}
                          </Button>
                          <Button
                            variant="secondary"
                            size="xs"
                            className="text-xs py-1"
                            onClick={() => setShowConfirmId(null)}
                            disabled={isLoading[officer.id]}
                          >
                            Cancel
                          </Button>
                        </div>
                      ) : (
                        <div className="dropdown dropdown-right">
                          <Button
                            size="xs"
                            variant="success"
                            onClick={() => toggleDropdown(officer.id)}
                          >
                            <span>Active</span>
                            <ChevronDownIcon className="h-3 w-3" />
                          </Button>
                          {openDropdownId === officer.id && (
                            <ul
                              tabIndex={0}
                              className="dropdown-content z-[100] menu p-1 shadow bg-white rounded-lg w-40 border border-gray-200 absolute right-0 top-full mt-1"
                            >
                              <li>
                                <a
                                  className="text-xs text-red-600"
                                  onClick={() => {
                                    setOpenDropdownId(null);
                                    setShowConfirmId(officer.id);
                                  }}
                                >
                                  Remove
                                </a>
                              </li>
                            </ul>
                          )}
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4} className="p-4 text-center text-gray-500">
                    No members yet
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pending Invites Section */}
      <div>
        <div className="overflow-visible">
          <h2 className="card-title">Pending Invites ({invitees.length})</h2>
          <table className="w-full border-collapse mt-2">
            <thead className="bg-gray-50">
              <tr>
                <th className="p-2 w-1/3 text-left text-sm font-semibold text-gray-500">
                  Email
                </th>
                <th className="p-2 w-1/3 text-left text-sm font-semibold text-gray-500">
                  Invited At
                </th>
                <th className="p-2 w-1/3 text-left text-sm font-semibold text-gray-500">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {invitees.length > 0 ? (
                invitees.map((officer) => (
                  <tr key={officer.id} className="hover:bg-gray-50">
                    <td className="p-2 whitespace-nowrap text-sm text-gray-500 font-semibold">
                      {officer.email}
                    </td>
                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">
                      {formatDate(officer.invitedAt || officer.createdAt)}
                    </td>
                    <td className="p-2 text-sm whitespace-nowrap relative">
                      {showConfirmId === officer.id ? (
                        <div className="flex space-x-2">
                          <Button
                            variant="danger"
                            size="xs"
                            className="text-xs py-1"
                            onClick={() =>
                              handleCancelInvite(officer.id, officer.email)
                            }
                            disabled={isLoading[officer.id]}
                          >
                            {isLoading[officer.id] ? "Removing..." : "Confirm"}
                          </Button>
                          <Button
                            variant="secondary"
                            size="xs"
                            className="text-xs py-1"
                            onClick={() => setShowConfirmId(null)}
                            disabled={isLoading[officer.id]}
                          >
                            Cancel
                          </Button>
                        </div>
                      ) : (
                        <div className="dropdown dropdown-right">
                          <Button
                            size="xs"
                            variant="warning"
                            onClick={() => toggleDropdown(officer.id)}
                          >
                            <span>Pending</span>
                            <ChevronDownIcon className="h-3 w-3" />
                          </Button>
                          {openDropdownId === officer.id && (
                            <ul
                              tabIndex={0}
                              className="dropdown-content z-[100] menu p-1 shadow bg-white rounded-lg w-40 border border-gray-200 absolute right-0 top-full mt-1"
                            >
                              <li>
                                <a
                                  className="text-xs"
                                  onClick={() => handleResendInvite(officer.id)}
                                >
                                  Resend
                                </a>
                              </li>
                              <li className="border-t border-gray-100">
                                <a
                                  className="text-xs text-red-600"
                                  onClick={() => {
                                    setOpenDropdownId(null);
                                    setShowConfirmId(officer.id);
                                  }}
                                >
                                  Remove
                                </a>
                              </li>
                            </ul>
                          )}
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4} className="p-4 text-center text-gray-500">
                    No pending invites
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OfficerTable;

import { BubbleMenu } from "@tiptap/react";
import React, { useState } from "react";
import { Wand2Icon, Loader2 } from "lucide-react";
import axios from "axios";
import { toast } from "react-hot-toast";
import EnhanceCompareModal from "../EnhanceCompareModal";
import cn from "classnames";
import { convertHtmlToPlainText } from "../../utilities/converHtmlToPlainText";

const FloatingMenu = ({ editor }) => {
  const [isEnhancing, setIsEnhancing] = useState(false);
  const [showCompareModal, setShowCompareModal] = useState(false);
  const [originalContent, setOriginalContent] = useState("");
  const [enhancedContent, setEnhancedContent] = useState("");
  const [selectionRange, setSelectionRange] = useState({ from: 0, to: 0 });

  const handleEnhance = async () => {
    if (!editor || isEnhancing) return;

    const { from, to } = editor.state.selection;
    const selectedContent = editor.state.doc
      .slice(from, to)
      .content.textBetween(0, to - from, "\n", "\n");

    if (!selectedContent) {
      toast.error("Please select some text to enhance");
      return;
    }

    try {
      setIsEnhancing(true);
      setSelectionRange({ from, to });
      setOriginalContent(selectedContent);

      const fullReport = convertHtmlToPlainText(editor.getHTML());

      const response = await axios.post(
        "/api/enhance/reword",
        {
          content: selectedContent,
          fullReport,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setEnhancedContent(response.data.enhancedContent);
      setShowCompareModal(true);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorMessage =
          error.response?.data?.error ||
          "Failed to enhance text, please check your internet connection.";
        toast.error(errorMessage);
      } else {
        toast.error(
          "An unexpected error occurred while enhancing text, please check your internet connection."
        );
      }
    } finally {
      setIsEnhancing(false);
    }
  };

  const handleAcceptChanges = () => {
    const formattedContent = enhancedContent
      .split("\n")
      .map((para) => para.trim())
      .filter((para) => para.length > 0)
      .map((para) => `<p>${para}</p>`)
      .join("");

    editor
      .chain()
      .focus()
      .deleteRange(selectionRange)
      .insertContent(formattedContent)
      .run();

    setShowCompareModal(false);
  };

  const handleDiscardChanges = () => {
    setShowCompareModal(false);
  };

  return (
    <>
      <BubbleMenu
        className={cn(
          showCompareModal && "hidden",
          "flex bg-white shadow-md border border-gray-200 rounded-lg p-1"
        )}
        tippyOptions={{ duration: 100 }}
        editor={editor}
      >
        <button
          onClick={handleEnhance}
          disabled={isEnhancing}
          className="flex items-center gap-2 px-3 py-1 rounded-lg hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed text-sm font-normal"
        >
          {isEnhancing ? (
            <Loader2 className="w-4 h-4 animate-spin" />
          ) : (
            <Wand2Icon className="w-4 h-4 text-blue-500" />
          )}
          <span>{isEnhancing ? "Enhancing..." : "Enhance Writing"}</span>
        </button>
      </BubbleMenu>

      <EnhanceCompareModal
        isOpen={showCompareModal}
        onClose={() => setShowCompareModal(false)}
        originalContent={originalContent}
        enhancedContent={enhancedContent}
        onAccept={handleAcceptChanges}
        onDiscard={handleDiscardChanges}
      />
    </>
  );
};

export default FloatingMenu;

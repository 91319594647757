import { InputHTMLAttributes, ReactNode, useState } from "react";
import Button from "../common/Button";
import Input from "../common/Input";
import TooltipIcon from "../common/TooltipIcon";

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  action?: string;
  description: string | ReactNode;
  id?: string;
  placeholder?: string;
  onSave?: (value: string) => void;
}

export default function TextInput({
  label,
  action,
  description,
  id,
  placeholder,
  value = "",
  onSave,
  ...props
}: TextInputProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState(value as string);
  const [tempValue, setTempValue] = useState(value as string);
  const uniqueId = id || `input-${label.toLowerCase().replace(/\s+/g, "-")}`;

  const handleSave = () => {
    setInputValue(tempValue);
    if (onSave) {
      onSave(tempValue);
    }
    setIsModalOpen(false);
  };

  const resetModal = () => {
    setIsModalOpen(false);
    setTempValue(inputValue);
  };

  return (
    <fieldset className="form-control w-full">
      <div className="label p-0 min-h-10">
        <span className="flex items-center">
          <span className="text-gray-800 font-medium text-xs md:text-base">
            {label}
          </span>
          <TooltipIcon description={description} />
        </span>
        <Button
          variant="outline"
          size="sm"
          onClick={() => setIsModalOpen(true)}
        >
          {inputValue || action || label}
        </Button>
      </div>

      <dialog
        id={`modal-${uniqueId}`}
        className={`modal ${isModalOpen ? "modal-open" : ""}`}
      >
        <div className="modal-box max-w-96">
          <h3 className="font-bold text-lg mt-2">
            {action || label}
          </h3>
          <div className="py-1">
            <Input
              type="text"
              value={tempValue}
              onChange={(e) => setTempValue(e.target.value)}
              placeholder={placeholder}
              className="!w-full !max-w-full"
            />
          </div>
          <div className="modal-action mt-2">
            <Button variant="outline" onClick={resetModal}>
              Cancel
            </Button>
            <Button onClick={handleSave} variant="primary">
              Update
            </Button>
          </div>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button onClick={resetModal}>close</button>
        </form>
      </dialog>
    </fieldset>
  );
}

import EmailInviteInput from "./EmailInviteInput";

interface OfficerInviteProps {
  maxOfficers: number;
  currentOfficers: number;
  availableSeats: number; // Add this prop
  onInvite?: (emails: string[]) => void;
}

const OfficerInvite: React.FC<OfficerInviteProps> = ({
  maxOfficers,
  currentOfficers,
  availableSeats, // Use the prop directly
  onInvite,
}) => {
  const handleInvite = (emails: string[]) => {
    if (onInvite) {
      onInvite(emails);
    }
  };

  return (
    <>
      <div className="card border border-gray-200 bg-white">
        <div className="card-body">
          <h2 className="card-title">Invite Officers</h2>
          <p className="text-gray-600 mb-4">
            Enter email addresses of officers to invite to your agency. Press
            Enter, Tab, Space or add a comma after each email.
          </p>

          <EmailInviteInput
            maxSeats={availableSeats} // Use availableSeats here
            onInvite={handleInvite}
            placeholder="Add emails..."
          />
        </div>
      </div>
      <p className="text-gray-500 text-sm mt-4 text-center">
        {availableSeats === 0 ? (
          <span className="text-red-500">
            You have used all available seats. Please contact{" "}
            <a href="mailto:support@policenarratives.ai">
              support@policenarratives.ai
            </a>{" "}
            to increase your limit.
          </span>
        ) : (
          <span>
            Please contact{" "}
            <a href="mailto:support@policenarratives.ai">
              support@policenarratives.ai
            </a>{" "}
            to adjust your number of seats.
          </span>
        )}
      </p>
    </>
  );
};

export default OfficerInvite;

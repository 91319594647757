import { XMarkIcon } from "@heroicons/react/24/outline";
import { CloudArrowUpIcon } from "@heroicons/react/24/solid";
import { DragEvent, useRef, useState } from "react";
import Button from "../common/Button";

interface UploadModalProps {
  modalRef: React.RefObject<HTMLDialogElement>;
  onUpload: (file: File) => void;
}

const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20MB in bytes

const UploadModal = ({ modalRef, onUpload }: UploadModalProps) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [error, setError] = useState<string>("");
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDragOver = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const isValidFile = (file: File) => {
    setError("");
    if (file.size > MAX_FILE_SIZE) {
      setError("File size must be less than 20MB");
      return false;
    }
    return true;
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    if (file && isValidFile(file)) {
      setSelectedFile(file);
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && isValidFile(file)) {
      setSelectedFile(file);
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      onUpload(selectedFile);
      setSelectedFile(null);
      modalRef.current?.close();
    }
  };

  return (
    <dialog ref={modalRef} className="modal">
      <div className="modal-box">
        <div className="flex justify-between items-center mb-4">
          <h3 className="font-bold text-lg">Upload Audio</h3>
          <button onClick={() => modalRef.current?.close()}>
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>

        <div
          className="border-2 border-dashed rounded-lg p-8 text-center cursor-pointer hover:bg-gray-50"
          onClick={() => fileInputRef.current?.click()}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileSelect}
            accept="audio/*,.m4a,.mp3,.wav,.aac,.alac,.aiff"
            className="hidden"
          />
          <CloudArrowUpIcon className="size-12 mx-auto text-blue-400" />
          <p className="text-gray-800 text-sm font-medium max-w-96 mx-auto">
            Drag and drop an audio file here or click to select.
          </p>
          <p className="text-gray-500 text-sm max-w-96 mx-auto">
            Upload only your first-person narrative - <b>not</b> interviews,
            suspect interactions, or third-party conversations.
          </p>
          {selectedFile && (
            <p className="mt-2 text-sm text-blue-500">{selectedFile.name}</p>
          )}
        </div>
        <div className="flex justify-between mt-2">
          <p className="text-sm text-gray-800">
            Supported formats: mp3, m4a, wav
          </p>
          <p className="text-sm text-gray-800">Max: 20MB</p>
        </div>
        {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
        <div className="modal-action">
          <Button
            onClick={handleUpload}
            disabled={!selectedFile || error !== ""}
            variant="primary"
            className="w-full"
          >
            Upload
          </Button>
        </div>
      </div>
    </dialog>
  );
};

export default UploadModal;

import { loadStripe } from "@stripe/stripe-js";
import axios, { AxiosError } from "axios";
import { FormEvent, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import bgImg from "../../pnai-admin-bg.jpg";
import { ReactComponent as Logo } from "../../police-narratives-ai.svg";
import Button from "../common/Button";
import Input from "../common/Input";
import Loading from "../common/Loading";
import { Country, State } from "country-state-city";

// Initialize Stripe
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY ||
    "pk_live_51PydONG4bf6K0RLmdfEJzwpzVpDCNNdt0HjC5sn5QokyLJij6TflI306Xoi69SSqHORZ9cPnt3xw2leQx9rrYb0B00nka5yr7n"
);

// Add these utility functions at the top level
const getPriceId = (searchParams: URLSearchParams) =>
  searchParams.get("priceId");
const appendSearchParams = (path: string, searchParams: URLSearchParams) =>
  `${path}${searchParams.size ? `?${searchParams.toString()}` : ""}`;

function App() {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [department, setDepartment] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [zip, setZip] = useState<string>("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [errorState, setErrorState] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated, setIsAuthenticated, setUser } = useAuth();
  const [searchParams] = useSearchParams();
  const [states, setStates] = useState<any[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<any>(
    Country.getCountryByCode("US")
  );

  useEffect(() => {
    if (selectedCountry) {
      const countryStates = State.getStatesOfCountry(selectedCountry.isoCode);
      setStates(countryStates);
      if (countryStates.length > 0) {
        setState(countryStates[0].isoCode);
      }
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (isAuthenticated && !getPriceId(searchParams)) {
      setLoading(true);
      navigate("/agency/dashboard");
    }
  }, [navigate, isAuthenticated, searchParams]);

  const validateForm = () => {
    let newErrors: {
      email?: string;
      password?: string;
      department?: string;
      address?: string;
      city?: string;
      state?: string;
      zip?: string;
      phone?: string;
    } = {};

    // Department validation
    if (!department) {
      newErrors.department = "Department is required";
    }

    // Email validation
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email is invalid";
    }

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{12,}$/;

    // Password validation
    if (!password) {
      newErrors.password = "Password is required";
    } else if (!passwordRegex.test(password)) {
      newErrors.password =
        "Password must be at least 12 characters long and include a combination of uppercase and lowercase letters, numbers, and symbols";
    }

    // Address validation
    if (!address.trim()) {
      newErrors.address = "Address is required";
    }

    // City validation
    if (!city.trim()) {
      newErrors.city = "City is required";
    }

    // State validation
    if (!state.trim()) {
      newErrors.state = "State is required";
    }

    // ZIP validation
    const zipRegex = /^\d{5}(-\d{4})?$/;
    if (!zip.trim()) {
      newErrors.zip = "ZIP code is required";
    } else if (!zipRegex.test(zip)) {
      newErrors.zip = "Invalid ZIP code format";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        const response = await axios.post("/api/auth/agency/signup", {
          email,
          password,
          name: department,
          address,
          city,
          state,
          zip,
          country: "US",
        });

        if (response.data.agency) {
          setIsAuthenticated(true);
          setUser(response.data.agency);
          navigate("/agency/dashboard");
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setErrorState(true);
          const axiosError = error as AxiosError<{ error: string }>;
          if (axiosError.response) {
            setErrors({
              overall:
                axiosError.response.data.error || "An unknown error occurred",
            });
          }
        }
        setLoading(false);
      }
    } else {
      setErrorState(true);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      <div
        className="hidden md:flex relative items-center justify-center bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${bgImg})` }}
      >
        <div className="absolute inset-0 bg-blue-50/70"></div>
        <div className="w-3/5 z-10">
          <Logo className="w-full h-auto" />
        </div>
      </div>
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="bg-white p-8 rounded-xl shadow-md w-full max-w-sm">
          <h1 className="text-3xl mb-2 font-semibold">Agency Signup</h1>
          <p className="mb-3 text-xs text-gray-500">
            Sign up for an account to purchase multiple seats.
          </p>

          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <p className="font-semibold pb-1 text-sm">Department</p>
              <Input
                type="text"
                id="department"
                value={department}
                placeholder="Full Department Name"
                onChange={(e) => {
                  errorState && setErrorState(false);
                  setDepartment(e.target.value);
                }}
                className={`input w-full ${errorState && "border-red-500"}`}
              />
              {errors.department && (
                <p className="mt-1 text-xs text-red-500">{errors.department}</p>
              )}
            </div>
            <div>
              <p className="font-semibold pb-1 text-sm">Email</p>
              <Input
                type="email"
                id="email"
                value={email}
                placeholder="Email Address"
                onChange={(e) => {
                  errorState && setErrorState(false);
                  setEmail(e.target.value);
                }}
                className={`input w-full ${errorState && "border-red-500"}`}
              />
              {errors.email && (
                <p className="mt-1 text-xs text-red-500">{errors.email}</p>
              )}
            </div>
            <div>
              <p className="font-semibold pb-1 text-sm">Password</p>
              <Input
                type="password"
                id="password"
                value={password}
                placeholder="Password"
                onChange={(e) => {
                  errorState && setErrorState(false);
                  setPassword(e.target.value);
                }}
                className={`input w-full max-w-xs ${
                  errorState && "border-red-500"
                }`}
              />
              {errors.password && errorState && (
                <p className="mt-1 text-xs text-red-500">{errors.password}</p>
              )}
              {errors.overall && errorState && (
                <p className="mt-1 text-xs text-red-500">{errors.overall}</p>
              )}
            </div>
            <div>
              <p className="font-semibold pb-1 text-sm">Agency Address</p>
              <Input
                type="text"
                value={address}
                placeholder="Street Address"
                onChange={(e) => {
                  errorState && setErrorState(false);
                  setAddress(e.target.value);
                }}
                className={`input w-full ${
                  errorState && errors.address && "border-red-500"
                }`}
              />
              {errors.address && (
                <p className="mt-1 text-xs text-red-500">{errors.address}</p>
              )}
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="font-semibold pb-1 text-sm">City</p>
                <Input
                  type="text"
                  value={city}
                  placeholder="City"
                  onChange={(e) => {
                    errorState && setErrorState(false);
                    setCity(e.target.value);
                  }}
                  className={`input w-full ${
                    errorState && errors.city && "border-red-500"
                  }`}
                />
                {errors.city && (
                  <p className="mt-1 text-xs text-red-500">{errors.city}</p>
                )}
              </div>

              <div>
                <p className="font-semibold pb-1 text-sm">State</p>
                <select
                  value={state}
                  onChange={(e) => {
                    errorState && setErrorState(false);
                    setState(e.target.value);
                  }}
                  className={`select select-bordered text-md w-full border-grey-500 bg-grey-50 text-base ${
                    errorState && errors.state && "select-error"
                  }`}
                >
                  {states.map((state) => (
                    <option key={state.isoCode} value={state.isoCode}>
                      {state.name}
                    </option>
                  ))}
                </select>
                {errors.state && (
                  <p className="mt-1 text-xs text-red-500">{errors.state}</p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="font-semibold pb-1 text-sm">ZIP Code</p>
                <Input
                  type="text"
                  value={zip}
                  placeholder="ZIP Code"
                  onChange={(e) => {
                    errorState && setErrorState(false);
                    setZip(e.target.value);
                  }}
                  className={`input w-full ${
                    errorState && errors.zip && "border-red-500"
                  }`}
                />
                {errors.zip && (
                  <p className="mt-1 text-xs text-red-500">{errors.zip}</p>
                )}
              </div>
            </div>
            <div>
              <Button className="w-full" variant="primary" type="submit">
                Sign up
              </Button>
            </div>
          </form>
          <div className="flex justify-center mt-4">
            <p className="font-semibold text-sm text-gray-500">
              Already have an agency account?{" "}
              <Link to={appendSearchParams("/agency/login", searchParams)}>
                Login
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;

import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./index.css";
import { AuthProvider } from "./context/AuthContext";
import { AgencyAuthProvider } from "./context/AgencyAuthContext";
import App from "./components/App";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Dashboard from "./components/Dashboard";
import Settings from "./components/Settings/Settings";
import ProtectedRoute from "./components/ProtectedRoute";
import reportWebVitals from "./reportWebVitals";
import Subscription from "./components/Subscription";
import axios from "axios";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import PurchaseConfirmation from "./components/PurchaseConfirmation";
import Preferences from "./components/Settings/Preferences";
import PostSignup from "./components/PostSignup";
import AgencyLogin from "./components/Agency/Login";
import AgencySignup from "./components/Agency/Signup";
import AgencyDashboard from "./components/Agency/Dashboard";
import AgencyProtectedRoute from "./components/AgencyProtectedRoute";

axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <AuthProvider>
    <AgencyAuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/health" element={<h3>The App is Healthy</h3>} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/post-signup" element={<PostSignup />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/confirmation" element={<PurchaseConfirmation />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/agency/login" element={<AgencyLogin />} />
          <Route path="/agency/signup" element={<AgencySignup />} />

          {/* Agency Protected Routes */}
          <Route element={<AgencyProtectedRoute />}>
            <Route path="/agency/dashboard" element={<AgencyDashboard />} />
          </Route>

          {/* User Protected Routes */}
          <Route element={<ProtectedRoute />}>
            <Route element={<Dashboard />}>
              <Route path="cases" element={null} />
              <Route path="cases/:id" element={null} />
              <Route path="trash" element={null} />
              <Route path="trash/:id" element={null} />
              <Route path="settings" element={<Settings />} />
              <Route path="preferences" element={<Preferences />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </AgencyAuthProvider>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

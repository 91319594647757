import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { Agency } from "../db/schema";

interface AgencyAuthContextType {
  isAuthenticated: boolean;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  agency: Agency | null;
  setAgency: React.Dispatch<React.SetStateAction<Agency | null>>;
  loading: boolean;
}

const AgencyAuthContext = createContext<AgencyAuthContextType | undefined>(
  undefined
);

export const AgencyAuthProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [agency, setAgency] = useState<Agency | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await axios.get("/api/auth/agency");
        console.log("response");
        console.log(response);
        if (response.data.user && "max_users" in response.data.user) {
          setIsAuthenticated(true);
          setAgency(response.data.user);
        }
      } catch (error) {
        setIsAuthenticated(false);
        setAgency(null);
      } finally {
        setLoading(false);
      }
    };

    checkAuthStatus();
  }, []);

  return (
    <AgencyAuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        agency,
        setAgency,
        loading,
      }}
    >
      {children}
    </AgencyAuthContext.Provider>
  );
};

export const useAgencyAuth = () => {
  const context = useContext(AgencyAuthContext);
  if (context === undefined) {
    throw new Error("useAgencyAuth must be used within an AgencyAuthProvider");
  }
  return context;
};

import { loadStripe } from "@stripe/stripe-js";
import axios, { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAgencyAuth } from "../../context/AgencyAuthContext";
import { SubscriptionStatusType } from "../../db/schema";
import bgImg from "../../pnai-admin-bg.jpg";
import { ReactComponent as Logo } from "../../police-narratives-ai.svg";
import Button from "../common/Button";
import Input from "../common/Input";
import Loading from "../common/Loading";

// Add these utility functions at the top level
const getPriceId = (searchParams: URLSearchParams) =>
  searchParams.get("priceId");
const getRedirectTo = (searchParams: URLSearchParams) =>
  searchParams.get("redirectto");
const appendSearchParams = (path: string, searchParams: URLSearchParams) =>
  `${path}${searchParams.size ? `?${searchParams.toString()}` : ""}`;

function Login() {
  const [email, setEmail] = useState<string>(process.env.REACT_APP_EMAIL || "");
  const [password, setPassword] = useState<string>(
    process.env.REACT_APP_PASSWORD || ""
  );
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [errorState, setErrorState] = useState<boolean>(false);
  const navigate = useNavigate();
  const { setIsAuthenticated, setAgency } = useAgencyAuth();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const checkAuthStatus = async () => {
      setLoading(true);
      try {
        const response = await axios.get("/api/auth/agency");
        if (response.data.user && response.data.user.type === "agency") {
          setIsAuthenticated(true);
          setAgency(response.data.user);
          navigate("/agency/dashboard");
        } else {
          setIsAuthenticated(false);
          setAgency(null);
        }
      } catch (error) {
        setIsAuthenticated(false);
        setAgency(null);
      } finally {
        setLoading(false);
      }
    };

    checkAuthStatus();
  }, [navigate, setIsAuthenticated, setAgency, searchParams]);

  const validateForm = () => {
    let newErrors: {
      email?: string;
      password?: string;
    } = {};

    // Email validation
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email is invalid";
    }

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{12,}$/;

    // Password validation
    if (!password) {
      newErrors.password = "Password is required";
    } else if (!passwordRegex.test(password)) {
      newErrors.password =
        "Password must be at least 12 characters long and include a combination of uppercase and lowercase letters, numbers, and symbols";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        const response = await axios.post("/api/auth/agency/login", {
          email,
          password,
        });

        setIsAuthenticated(true);
        setAgency(response.data.agency);
        navigate("/agency/dashboard");
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setErrorState(true);
          const axiosError = error as AxiosError<{ error: string }>;
          if (axiosError.response) {
            console.error(axiosError.response.data.error);
            setErrors({
              overall: "Incorrect login credentials.",
            });
          }
        }
      } finally {
        setLoading(false);
      }
    } else {
      setErrorState(true);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      <div
        className="hidden md:flex relative items-center justify-center bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${bgImg})` }}
      >
        <div className="absolute inset-0 bg-blue-50/70"></div>
        <div className="w-3/5 z-10">
          <Logo className="w-full h-auto" />
        </div>
      </div>
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="bg-white p-8 rounded-xl shadow-md w-full max-w-sm">
          <h1 className="text-3xl mb-4 font-semibold">Agency Login</h1>

          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <p className="font-semibold pb-1 text-sm">Admin Email</p>
              <Input
                type="email"
                id="email"
                value={email}
                placeholder="Email address"
                onChange={(e) => {
                  errorState && setErrorState(false);
                  setEmail(e.target.value);
                }}
                className={`input w-full ${errorState && "border-red-500"}`}
              />
              {errors.email && errorState && (
                <p className="mt-1 text-xs text-red-500">{errors.email}</p>
              )}
            </div>
            <div>
              <p className="font-semibold pb-1 text-sm">Password</p>
              <Input
                type="password"
                id="password"
                value={password}
                placeholder="Password"
                onChange={(e) => {
                  errorState && setErrorState(false);
                  setPassword(e.target.value);
                }}
                className={`input w-full max-w-xs ${
                  errorState && "border-red-500"
                }`}
              />
              {errors.password && errorState && (
                <p className="mt-1 text-xs text-red-500">{errors.password}</p>
              )}
              {errors.overall && errorState && (
                <p className="mt-1 text-xs text-red-500">{errors.overall}</p>
              )}
            </div>
            <div>
              <Button className="w-full" variant="primary" type="submit">
                Login
              </Button>
            </div>
          </form>
          <div className="flex justify-center mt-4">
            <p className="font-semibold text-sm text-gray-500">
              Don't have an agency account?{" "}
              <Link to={appendSearchParams("/agency/signup", searchParams)}>
                Sign up
              </Link>
            </p>
          </div>
          <div className="flex justify-center mt-2">
            <p className="font-normal text-xs text-gray-500">
              Switch to{" "}
              <Link to={appendSearchParams("/login", searchParams)}>
                Individual User view
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

import axios from "axios";
import DOMPurify from "dompurify";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import {
  SubscriptionPlatformType,
  SubscriptionStatusType,
} from "../../db/schema";
import Button from "../common/Button";
import Input from "../common/Input";

// Add sanitization utilities
const sanitizeInput = (value: string): string => {
  return DOMPurify.sanitize(value);
};

interface SettingProps {
  field: string;
  value?: string | null;
  buttonText: string;
  type:
    | "name"
    | "email"
    | "badgeId"
    | "password"
    | "department"
    | "delete"
    | "billing";
  isDanger?: boolean;
  disabled?: boolean; // Add this prop
}

const Setting = ({
  field,
  value,
  buttonText,
  type,
  isDanger,
  disabled, // Add this prop
}: SettingProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState(
    type === "password" || type === "delete" ? "" : value || ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const { setUser, user } = useAuth();
  const { subscriptionStatus } = useAuth();
  const navigate = useNavigate();

  const handleBillingRedirect = async () => {
    setIsLoading(true);
    try {
      if (subscriptionStatus === SubscriptionStatusType.enum.trialing) {
        navigate("/subscription");
        return;
      }
      const { data } = await axios.post("/api/subscription/portal");
      window.location.href = data.url;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.error || "An error occurred";
        toast.error(errorMessage);
      } else {
        toast.error("An unexpected error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleButtonClick = () => {
    if (type === "billing") {
      handleBillingRedirect();
    } else {
      setIsModalOpen(true);
    }
  };

  const resetModal = () => {
    setIsModalOpen(false);
    setInputValue(type === "password" || type === "delete" ? "" : value || "");
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      // Add special handling for billing
      if (type === "billing") {
        const { data } = await axios.post("/api/subscription/portal");
        window.location.href = data.url;
        return;
      }

      // Special handling for delete
      // if (type === "delete") {
      //   if (inputValue !== "delete account") {
      //     toast.error("Please type 'delete account' to confirm");
      //     return;
      //   }

      //   await axios.post("/api/settings/account", {
      //     type: "delete",
      //   });

      //   toast.success("Account deleted successfully");
      //   window.location.href = "/login";
      //   return;
      // }

      const sanitizedValue = sanitizeInput(inputValue);
      const { data } = await axios.post("/api/settings/account", {
        type,
        value: sanitizedValue,
      });

      setUser(data.user);
      toast.success(`${field} updated successfully`);
      setIsModalOpen(false);
      setInputValue("");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Handle Axios-specific errors
        const errorMessage = error.response?.data?.error || "An error occurred";
        toast.error(errorMessage);
      } else {
        // Handle non-Axios errors
        toast.error("An unexpected error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="flex items-center justify-between pb-6">
        <div className="flex flex-col">
          <p className="m-0 font-bold text-sm">{field}</p>
          <p className="m-0 text-gray-500 text-sm">{value}</p>
        </div>
        <Button
          variant={isDanger ? "danger" : "outline"}
          size="sm"
          onClick={handleButtonClick}
          disabled={disabled} // Add this prop
        >
          {buttonText}
        </Button>
      </div>

      {type !== "billing" && (
        <dialog
          id={`modal-${type}`}
          className={`modal ${isModalOpen ? "modal-open" : ""}`}
        >
          <div className="modal-box max-w-96">
            <h3 className="font-bold text-lg mt-2">
              {type === "delete" ? "Delete Account" : `Update ${field}`}
            </h3>
            <div className="py-1">
              {type === "delete" ? (
                <>
                  <p className="text-sm text-red-500 m-0 pb-4">
                    This action cannot be undone. Type "delete account" to
                    confirm.
                  </p>
                  <Input
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    placeholder="Type 'delete account' to confirm"
                    className="!w-full !max-w-full"
                  />
                </>
              ) : (
                <Input
                  type={type === "password" ? "password" : "text"}
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder={`Enter new ${field.toLowerCase()}`}
                  className="!w-full !max-w-full"
                />
              )}
            </div>
            <div className="modal-action">
              <Button variant="outline" onClick={resetModal}>
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                disabled={
                  isLoading ||
                  (type === "delete" && inputValue !== "delete account")
                }
                variant={
                  isDanger && inputValue === "delete account"
                    ? "danger"
                    : "primary"
                }
              >
                {isLoading
                  ? "Processing..."
                  : type === "delete"
                  ? "Confirm Delete"
                  : "Save"}
              </Button>
            </div>
          </div>
          <form method="dialog" className="modal-backdrop">
            <button onClick={resetModal}>close</button>
          </form>
        </dialog>
      )}
    </>
  );
};

const Settings = () => {
  const [activeComponent, setActiveComponent] = useState<"account">("account");
  const { user, setIsAuthenticated, subscriptionStatus, subscriptionPlatform } =
    useAuth();
  const navigate = useNavigate();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const SettingsNav = () => (
    <div className="flex overflow-x-auto top-0 space-x-1 pb-4">
      <Button
        variant={activeComponent === "account" ? "secondary" : "clean"}
        size="sm"
        onClick={() => setActiveComponent("account")}
        className="animate-none"
      >
        Account
      </Button>
    </div>
  );

  const handleLogout = async () => {
    setIsLoggingOut(true);
    try {
      await axios.post("/api/auth/logout");
      setIsAuthenticated(false);
      navigate("/login");
    } catch (error) {
      console.error("Logout failed:", error);
      setIsLoggingOut(false);
    }
  };

  function Account() {
    // const canDeleteAccount = subscriptionStatus !== "active";

    return (
      <>
        <h2 className="mt-2 border-b-2 pb-2">Account</h2>
        <Setting
          field="Full Name"
          value={user?.name}
          buttonText="Change Name"
          type="name"
        />
        <Setting
          field="Email"
          value={user?.email}
          buttonText="Change Email"
          type="email"
          disabled={!!user?.agency_id}
        />
        <Setting
          field="Badge"
          value={user?.badgeID}
          buttonText="Change Badge #"
          type="badgeId"
        />
        <Setting
          field="Password"
          value="Set a permanent password to login to your account"
          buttonText="Change Password"
          type="password"
        />
        <Setting
          field="Department"
          value={user?.department}
          buttonText="Change Department"
          type="department"
          disabled={!!user?.agency_id}
        />

        <Setting
          field="Billing"
          value={
            subscriptionStatus === SubscriptionStatusType.enum.trialing
              ? "Unlock full access by choosing a plan"
              : subscriptionPlatform === SubscriptionPlatformType.enum.apple
              ? "This subscription is billed through Apple, manage via App Store"
              : "Change or cancel your subscription"
          }
          buttonText={
            subscriptionStatus === SubscriptionStatusType.enum.trialing
              ? "Choose Plan"
              : "Manage Plan"
          }
          type="billing"
          disabled={
            subscriptionPlatform === SubscriptionPlatformType.enum.apple ||
            !!user?.agency_id
          }
        />
        {/* <Setting
          field="Delete Account"
          value="Permanently delete your account and all associated data"
          buttonText="Delete Account"
          type="delete"
          isDanger={true}
          disabled={!canDeleteAccount}
        /> */}
        <div className="flex justify-end mt-4">
          <Button
            onClick={handleLogout}
            disabled={isLoggingOut}
            variant="primary"
          >
            Logout
          </Button>
        </div>
      </>
    );
  }

  return (
    <div className="flex-1 p-6 flex flex-col w-full prose items-center overflow-y-auto mt-8 md:mt-0">
      <div className="max-w-5xl mt-8 w-full">
        <h1 className="text-4xl font-semibold text-black-400 capitalize w-full text-center">
          Settings
        </h1>
        {/* <SettingsNav /> */}
        {activeComponent === "account" && <Account />}
      </div>
    </div>
  );
};

export default Settings;

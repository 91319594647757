export function convertHtmlToPlainText(html: string): string {
  return (
    html
      // Remove style attributes
      .replace(/\s*style="[^"]*"/g, "")

      // Remove data attributes
      .replace(/\s*data-[^=]*="[^"]*"/g, "")

      // Handle headings with double line break after
      .replace(/<h[1-6]>(.*?)<\/h[1-6]>/g, "$1\n\n")

      // Handle blockquotes with newlines
      .replace(/<blockquote><p>(.*?)<\/p><\/blockquote>/g, '\n"$1"\n\n')

      // Handle unordered lists
      .replace(/<ul>(\s*<li><p>.*?<\/p><\/li>\s*)*<\/ul>/g, (match) => {
        return (
          "\n" +
          match
            .replace(/<ul>|<\/ul>/g, "")
            .replace(/<li><p>(.*?)<\/p><\/li>/g, "- $1\n") +
          "\n"
        );
      })

      // Handle ordered lists with custom start attribute
      .replace(
        /<ol(?:\s+start="(\d+)")?>(\s*<li><p>.*?<\/p><\/li>\s*)*<\/ol>/g,
        (match, startNum) => {
          let number = parseInt(startNum || "1") - 1;
          return (
            "\n" +
            match
              .replace(/<ol(?:\s+start="\d+")?>|<\/ol>/g, "")
              .replace(/<li><p>(.*?)<\/p><\/li>/g, (_, content) => {
                number++;
                return `${number}. ${content}\n`;
              }) +
            "\n"
          );
        }
      )

      // Handle paragraphs with double line break
      .replace(/<p>(.*?)<\/p>/g, "$1\n\n")

      // Handle horizontal rules
      .replace(/<hr\s*\/?>/g, "\n---\n\n")

      // Handle line breaks
      .replace(/<br\s*\/?>/g, " ")

      // Remove strikethrough content completely
      .replace(/<s>.*?<\/s>/g, "")

      // Remove formatting tags but keep their content
      .replace(/<(mark|u|span|em|strong)[^>]*>(.*?)<\/\1>/g, "$2")

      // Remove any remaining HTML tags
      .replace(/<[^>]+>/g, "")

      // Clean up excessive newlines while preserving paragraph breaks
      .replace(/\n{3,}/g, "\n\n")

      // Trim extra whitespace at start/end
      .trim()
  );
}

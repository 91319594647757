import {
  pgTable,
  text,
  varchar,
  timestamp,
  uuid,
  date,
  time,
  jsonb,
  pgEnum,
  boolean,
  integer,
  decimal,
} from "drizzle-orm/pg-core";
import { z } from "zod";

// Define the platform enum
export const SubscriptionPlatform = pgEnum("subscription_platform", [
  "stripe",
  "apple",
  "google",
]);

export const SubscriptionPlatformType = z.enum(SubscriptionPlatform.enumValues);

export const SubscriptionStatus = pgEnum("subscription_status", [
  "active",
  "past_due",
  "canceled",
  "incomplete",
  "incomplete_expired",
  "trialing",
  "unpaid",
  "paused",
]);

export const SubscriptionStatusType = z.enum(SubscriptionStatus.enumValues);

export const ExternalAuthProvider = pgEnum("auth_provider", [
  "apple",
  "google",
  "email",
]);

export const ExternalAuthProviderType = z.enum(ExternalAuthProvider.enumValues);

export const PreferenceDateFormat = pgEnum("date_format", [
  "MMMM DD YYYY",
  "MM/DD/YYYY",
  "DD/MM/YYYY",
]);

export const PreferenceDateFormatType = z.enum(PreferenceDateFormat.enumValues);

export const PreferenceTimeFormat = pgEnum("time_format", [
  "military_time",
  "standard_time",
]);

export const PreferenceTimeFormatType = z.enum(PreferenceTimeFormat.enumValues);

export const PreferencePerspective = pgEnum("perspective", [
  "first_person",
  "third_person",
]);

export const PreferencePerspectiveType = z.enum(
  PreferencePerspective.enumValues
);

export const SubjectReferenceStyle = pgEnum("subject_reference_style", [
  "full_name_throughout",
  "last_name_after_first_mention",
  "role_after_first_mention",
  "role_with_last_name_after_first_mention",
]);

export const SubjectReferenceStyleType = z.enum(
  SubjectReferenceStyle.enumValues
);

export const users = pgTable("users", {
  id: uuid("id").primaryKey().defaultRandom(),
  agency_id: uuid("agency_id").references(() => agencies.id),
  stripe_customer_id: varchar("stripe_customer_id", { length: 255 }),
  name: text("name"),
  email: varchar("email", { length: 255 }).notNull().unique(),
  password: varchar("password", { length: 255 }),
  badgeID: varchar("badge_id", { length: 255 }),
  department: varchar("department", { length: 255 }),
  avatarUrl: text("avatar_url"),
  external_auth_id: varchar("external_auth_id", { length: 255 }),
  external_auth_provider: ExternalAuthProvider("external_auth_provider"),
  created_at: timestamp("created_at").defaultNow(),
  updated_at: timestamp("updated_at").defaultNow(),
  state: varchar("state", { length: 255 }),
  deleted_at: timestamp("deleted_at", { withTimezone: true }),
});

export type User = typeof users.$inferSelect;
export type NewUser = typeof users.$inferInsert;

export const reportPreferences = pgTable("report_preferences", {
  id: uuid("id").primaryKey().defaultRandom(),
  userId: uuid("user_id")
    .notNull()
    .unique()
    .references(() => users.id),
  badge_number: boolean("badge_number").default(true),
  name: boolean("name").default(true),
  top_sheet_information: boolean("top_sheet_information").default(false),
  topic_headings: boolean("topic_headings").default(false),
  abbreviate_rank: boolean("abbreviate_rank").default(true),
  day_of_week: boolean("day_of_week").default(true),
  date_format: PreferenceDateFormat("date_format").default("MMMM DD YYYY"),
  time_format: PreferenceTimeFormat("time_format").default("military_time"),
  perspective: PreferencePerspective("perspective").default("first_person"),
  subject_reference_style: SubjectReferenceStyle(
    "subject_reference_style"
  ).default("role_with_last_name_after_first_mention"),
  header: text("header"),
  footer: text("footer"),
  additional_prompt: text("additional_prompt"),
  rank: text("rank"),
  created_at: timestamp("created_at").defaultNow(),
  updated_at: timestamp("updated_at").defaultNow(),
});

export type ReportPreferences = typeof reportPreferences.$inferSelect;
export type NewReportPreferences = typeof reportPreferences.$inferInsert;

export const subscriptions = pgTable("subscriptions", {
  id: uuid("id").primaryKey().defaultRandom(),
  user_id: uuid("user_id").references(() => users.id),
  agency_id: uuid("agency_id").references(() => agencies.id),
  platform: SubscriptionPlatform("platform")
    .notNull()
    .default(SubscriptionPlatformType.enum.stripe),
  platform_subscription_id: varchar("platform_subscription_id", {
    length: 255,
  }),
  receipt_data: text("receipt_data"),
  receipt_verified_at: timestamp("receipt_verified_at"),
  status: SubscriptionStatus("status").notNull(),
  current_period_end: timestamp("current_period_end").notNull(),
  cancel_at_period_end: boolean("cancel_at_period_end").default(false),
  is_trial: boolean("is_trial").default(false),
  payment_captured: boolean("payment_captured").default(false),
  price_id: varchar("price_id", { length: 255 }),
  currency: varchar("currency", { length: 3 }),
  interval: varchar("interval", { length: 50 }),
  metadata: jsonb("metadata"),
  created_at: timestamp("created_at").defaultNow(),
  updated_at: timestamp("updated_at").defaultNow(),
});

export type Subscription = typeof subscriptions.$inferSelect;
export type NewSubscription = typeof subscriptions.$inferInsert;

export const subscription_invoices = pgTable("subscription_invoices", {
  id: uuid("id").primaryKey().defaultRandom(),
  user_id: uuid("user_id").references(() => users.id),
  agency_id: uuid("agency_id").references(() => agencies.id),
  subscription_id: uuid("subscription_id")
    .notNull()
    .references(() => subscriptions.id),
  platform: SubscriptionPlatform("platform")
    .notNull()
    .default(SubscriptionPlatformType.enum.stripe),
  platform_invoice_id: varchar("platform_invoice_id", { length: 255 }).unique(),
  amount: decimal("amount", { precision: 10, scale: 2 }),
  status: varchar("status", { length: 50 }).notNull(),
  period_start: timestamp("period_start").notNull(),
  period_end: timestamp("period_end").notNull(),
  metadata: jsonb("metadata"),
  created_at: timestamp("created_at").defaultNow(),
});

export type SubscriptionInvoice = typeof subscription_invoices.$inferSelect;
export type NewSubscriptionInvoice = typeof subscription_invoices.$inferInsert;

export const CaseStatus = pgEnum("case_status", [
  "active",
  "archived",
  "discarded",
  "processing",
  "error",
]);

export const CaseStatusType = z.enum(CaseStatus.enumValues);

export const cases = pgTable("cases", {
  id: uuid("id").primaryKey().defaultRandom(),
  userId: uuid("user_id")
    .notNull()
    .references(() => users.id),
  caseNumber: varchar("case_number", { length: 50 }),
  incidentDate: date("incident_date"),
  incidentTime: time("incident_time"),
  status: CaseStatus("status").default("processing"),
  error: text("error"),
  content: jsonb("content"),
  audioUrl: text("audio_url"),
  createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
  updatedAt: timestamp("updated_at", { withTimezone: true }).defaultNow(),
  discardedAt: timestamp("discarded_at", { withTimezone: true }),
  actions: integer("actions").notNull().default(0),
});

export type Case = typeof cases.$inferSelect;
export type NewCase = typeof cases.$inferInsert;

export const suggestions = pgTable("suggestions", {
  id: uuid("id").primaryKey().defaultRandom(),
  userId: uuid("user_id")
    .notNull()
    .references(() => users.id),
  caseId: uuid("case_id")
    .notNull()
    .references(() => cases.id),
  content: text("content").notNull(),
  priority: integer("priority"),
  archived: boolean("archived").default(false),
  implemented: boolean("implemented").default(false),
  createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
  updatedAt: timestamp("updated_at", { withTimezone: true }).defaultNow(),
});

export type Suggestion = typeof suggestions.$inferSelect;
export type NewSuggestion = typeof suggestions.$inferInsert;

export const caseScores = pgTable("case_scores", {
  id: uuid("id").primaryKey().defaultRandom(),
  caseId: uuid("case_id")
    .notNull()
    .references(() => cases.id),
  archived: boolean("archived").default(false),
  createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
  score: integer("score"),
});

export type CaseScore = typeof caseScores.$inferSelect;
export type NewCaseScore = typeof caseScores.$inferInsert;

export const audits = pgTable("audits", {
  id: uuid("id").primaryKey().defaultRandom(),
  userId: uuid("user_id")
    .notNull()
    .references(() => users.id),
  caseId: uuid("case_id")
    .notNull()
    .references(() => cases.id),
  createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
  content: text("content").notNull(),
  score: integer("score"),
  actions: integer("actions"),
});

export type Audit = typeof audits.$inferSelect;
export type NewAudit = typeof audits.$inferInsert;

export const snippets = pgTable("snippets", {
  id: uuid("id").primaryKey().defaultRandom(),
  userId: uuid("user_id")
    .notNull()
    .references(() => users.id),
  createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
  updatedAt: timestamp("updated_at", { withTimezone: true }).defaultNow(),
  title: text("title").notNull(),
  content: text("content").notNull(),
  description: text("description"),
  priority: integer("priority"),
});

export type Snippet = typeof snippets.$inferSelect;
export type NewSnippet = typeof snippets.$inferInsert;

export const agencies = pgTable("agencies", {
  id: uuid("id").primaryKey().defaultRandom(),
  name: text("name").notNull(),
  email: varchar("email", { length: 255 }).notNull().unique(),
  password: varchar("password", { length: 255 }),
  stripe_customer_id: varchar("stripe_customer_id", { length: 255 }),
  address: text("address"),
  city: varchar("city", { length: 255 }),
  state: varchar("state", { length: 255 }),
  zip: varchar("zip", { length: 20 }),
  country: varchar("country", { length: 2 }).default("US"),
  max_users: integer("max_users").default(0),
  created_at: timestamp("created_at").defaultNow(),
  updated_at: timestamp("updated_at").defaultNow(),
  deleted_at: timestamp("deleted_at", { withTimezone: true }),
});

export type Agency = typeof agencies.$inferSelect;
export type NewAgency = typeof agencies.$inferInsert;

export const agency_invitations = pgTable("agency_invitations", {
  id: uuid("id").primaryKey().defaultRandom(),
  agency_id: uuid("agency_id")
    .notNull()
    .references(() => agencies.id),
  email: varchar("email", { length: 255 }).notNull(),
  token: varchar("token", { length: 255 }).notNull().unique(),
  accepted: boolean("accepted").default(false),
  expires_at: timestamp("expires_at").notNull(),
  created_at: timestamp("created_at").defaultNow(),
  updated_at: timestamp("updated_at").defaultNow(),
  deleted_at: timestamp("deleted_at", { withTimezone: true }),
});

export type AgencyInvitation = typeof agency_invitations.$inferSelect;
export type NewAgencyInvitation = typeof agency_invitations.$inferInsert;

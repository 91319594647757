import {
  ArrowUpOnSquareIcon,
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  ChatBubbleBottomCenterTextIcon,
  DocumentDuplicateIcon,
  MinusIcon,
  PencilIcon,
  XMarkIcon,
  ChatBubbleLeftEllipsisIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import {
  BoldIcon,
  ChevronDownIcon,
  ItalicIcon,
  ListBulletIcon,
  MicrophoneIcon,
  NumberedListIcon,
  PaintBrushIcon,
  StrikethroughIcon,
  UnderlineIcon,
} from "@heroicons/react/24/solid";
import { Editor as EditorProps } from "@tiptap/react";
import axios from "axios";
import cn from "classnames";
import moment from "moment";
import { ReactElement, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { SubscriptionStatusType } from "../../db/schema";
import { useIsMobile } from "../../hooks/useIsMobile";
import { convertHtmlToPlainText } from "../../utilities/converHtmlToPlainText";
import Button from "../common/Button";
import IconButton from "../common/IconButton";
import Input from "../common/Input";
import Tooltip from "../common/Tooltip";
import { Loader2 } from "lucide-react";

interface EditorMenuBarProps {
  editor: EditorProps | null;
  disabled?: boolean;
  caseId: string | undefined;
  score: number | null | undefined;
  onChange: (data: { content: { data: string }; caseId?: string }) => void;
  onTranscribeStateChange: (isTranscribing: boolean) => void;
}

const iconBtnStyles = "size-4";

const copySuccessToast = () => {
  toast.success("Copied to clipboard");
};

const copyToClipboardFallback = (text: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    try {
      const textarea = document.createElement("textarea");
      textarea.value = text;
      textarea.style.position = "fixed";
      textarea.style.opacity = "0";
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      resolve();
    } catch (err) {
      reject(err);
    }
  });
};

const EditorMenuBar = function ({
  editor,
  disabled,
  caseId,
  score,
  onChange,
  onTranscribeStateChange,
}: EditorMenuBarProps) {
  const [isTextColorPickerOpen, setIsTextColorPickerOpen] = useState(false);
  const [isHighlightColorPickerOpen, setIsHighlightColorPickerOpen] =
    useState(false);
  const [isHeadingDropdownOpen, setIsHeadingDropdownOpen] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [isTranscribing, setIsTranscribing] = useState(false);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const dialogRef = useRef<HTMLDialogElement>(null);
  const emailDialogRef = useRef<HTMLDialogElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const {
    subscriptionStatus,
    subscriptionCurrentPeriodEnd,
    refreshSubscriptionStatus,
  } = useAuth();
  const isMobile = useIsMobile();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsHeadingDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    refreshSubscriptionStatus();
  }, []);

  function extractTitle(content: string): string {
    const match = content.match(/<h1[^>]*>(.*?)<\/h1>/);
    return match ? match[1] : "";
  }

  const handleCopyToClipboard = async () => {
    if (editor) {
      try {
        const content = editor.getHTML();
        const plainText = convertHtmlToPlainText(content);

        await axios.post(
          "/api/audits",
          {
            caseId,
            content: plainText,
            score,
          },
          {
            withCredentials: true,
          }
        );

        try {
          await navigator.clipboard.writeText(plainText);
        } catch (err) {
          // Fallback for mobile devices
          await copyToClipboardFallback(plainText);
        }

        dialogRef.current?.close();
        copySuccessToast();
      } catch (error) {
        console.error("Error in copy process:", error);
        toast.error(
          error.response?.status === 401
            ? "Please log in to export content"
            : "Failed to export content"
        );
      }
    }
  };

  const validateEmail = (email: string) => {
    if (!email) {
      setEmailError("Email is required");
      return false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Email is invalid");
      return false;
    }
    setEmailError("");
    return true;
  };

  const handleEmailExport = async () => {
    if (editor && validateEmail(emailValue)) {
      setIsLoading(true);
      try {
        const htmlBody = editor.getHTML();
        const textBody = convertHtmlToPlainText(editor.getHTML());
        const title = extractTitle(htmlBody);

        await axios.post(
          "/api/email",
          {
            caseId,
            title,
            htmlBody,
            textBody,
            score,
            emailTo: emailValue,
          },
          {
            withCredentials: true,
          }
        );

        toast.success("Email sent successfully");
        emailDialogRef.current?.close();
        setEmailValue("");
      } catch (error) {
        console.error("Error in email process:", error);
        toast.error(
          error.response?.status === 401
            ? "Please log in to send email"
            : "Failed to send email"
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getTimeRemaining = () => {
    if (
      !subscriptionCurrentPeriodEnd &&
      subscriptionStatus === SubscriptionStatusType.enum.trialing
    )
      return "";
    const now = moment();
    const end = moment(subscriptionCurrentPeriodEnd);
    const diff = end.diff(now, "hours");

    if (diff < 24) {
      return `${diff} hours left in trial`;
    }
    return `${Math.ceil(diff / 24)} days left in trial`;
  };

  type HeadingLevel = 1 | 2 | 3;
  interface HeadingOption {
    name: ReactElement;
    level: HeadingLevel | 0;
  }
  const headingLevels: HeadingOption[] = [
    { name: <p>Paragraph</p>, level: 0 },
    { name: <h1>Heading 1</h1>, level: 1 },
    { name: <h2>Heading 2</h2>, level: 2 },
    { name: <h3>Heading 3</h3>, level: 3 },
  ];

  if (editor == null) {
    return null;
  }

  const getCurrentHeading = () => {
    const heading = headingLevels.find((h) =>
      h.level === 0
        ? editor.isActive("paragraph")
        : editor.isActive("heading", { level: h.level })
    );
    return heading ? heading.name : "Paragraph";
  };

  const applyHeading = (level: HeadingOption["level"]) => {
    if (level === 0) {
      editor.chain().focus().setParagraph().run();
    } else {
      editor.chain().focus().toggleHeading({ level }).run();
    }
    setIsHeadingDropdownOpen(false);
  };

  const startRecording = async (action: "pure-insert" | "enhanced-insert") => {
    try {
      setIsRecording(true);
      editor.setEditable(false);
      setIsTranscribing(true);
      onTranscribeStateChange(true);

      toast.loading("Recording audio...", {
        id: "transcribe",
        duration: Infinity, // Make the toast persist
      });

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;
      audioChunksRef.current = [];

      mediaRecorder.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorder.onstop = async () => {
        toast.loading("Transcribing audio...", {
          id: "transcribe",
          duration: Infinity,
        });

        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/wav",
        });
        const formData = new FormData();
        formData.append("audio", audioBlob, "recording.wav");

        // Get the full report context and insert cursor marker
        const cursorPosText = " {TEXT SNIPPET WILL BE ADDED HERE} ";
        const { head } = editor.state.selection;
        const beforeCursor = editor.state.doc.textBetween(0, head);
        const afterCursor = editor.state.doc.textBetween(
          head,
          editor.state.doc.content.size
        );
        const fullReport = beforeCursor + cursorPosText + afterCursor;

        formData.append("context", fullReport);
        formData.append("action", action);

        try {
          const response = await axios.post(
            "/api/transcribe/insert",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (response.data.content) {
            editor.setEditable(true);
            editor?.commands.insertContent(response.data.content);
            onChange({ content: { data: editor.getHTML() }, caseId });
            toast.success("Audio transcribed successfully", {
              id: "transcribe",
              duration: 5000,
            });
          }
        } catch (error) {
          console.error("Error transcribing audio:", error);
          toast.error("Failed to transcribe audio", { id: "transcribe" });
          editor.setEditable(true);
        } finally {
          setIsTranscribing(false);
          onTranscribeStateChange(false);
        }

        // Stop all tracks
        stream.getTracks().forEach((track) => track.stop());
      };

      mediaRecorder.start();
    } catch (error) {
      console.error("Error starting recording:", error);
      toast.error("Failed to start recording", { id: "transcribe" });
      setIsTranscribing(false);
      onTranscribeStateChange(false);
      editor.setEditable(true);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  return (
    <div className="sticky top-16 md:top-0 z-20 bg-gray-100 border-b-2">
      <div className="flex items-center justify-between w-full py-2 px-2">
        <div className="flex items-center space-x-1">
          <div className="relative inline-block" ref={dropdownRef}>
            <div>
              <IconButton
                type="button"
                id="heading-menu"
                aria-expanded="true"
                aria-haspopup="true"
                onClick={() => setIsHeadingDropdownOpen(!isHeadingDropdownOpen)}
                description="Styles"
                disabled={disabled}
              >
                <div className="flex items-center">
                  <p className="text-xs">{getCurrentHeading()}</p>
                  <ChevronDownIcon
                    className={cn(iconBtnStyles, "ml-2")}
                    aria-hidden="true"
                  />
                </div>
              </IconButton>
            </div>

            {isHeadingDropdownOpen && (
              <div
                className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="heading-menu"
              >
                <div className="py-1" role="none">
                  {headingLevels.map((heading) => (
                    <button
                      key={heading.level}
                      className={`block w-full text-left px-4 py-2 text-xs prose ${
                        heading.level === 0
                          ? editor.isActive("paragraph")
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700"
                          : editor.isActive("heading", { level: heading.level })
                          ? "bg-gray-100 text-gray-900"
                          : "text-gray-700"
                      } hover:bg-gray-100 hover:text-gray-900`}
                      role="menuitem"
                      onClick={() => applyHeading(heading.level)}
                    >
                      {heading.name}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="dropdown dropdown-bottom">
            <IconButton
              tabIndex={0}
              disabled={disabled}
              isActive={isRecording}
              description={
                isTranscribing
                  ? "Transcribing..."
                  : isRecording
                  ? "Stop Recording"
                  : "Transcribe Audio"
              }
              onClick={() => {
                if (isRecording) {
                  stopRecording();
                }
              }}
            >
              {isRecording ? (
                <MicrophoneIcon
                  className={cn(iconBtnStyles, "text-red-600 animate-flashing")}
                />
              ) : isTranscribing ? (
                <Loader2 className={cn(iconBtnStyles, "animate-spin")} />
              ) : (
                <MicrophoneIcon className={iconBtnStyles} />
              )}
            </IconButton>
            {!isRecording && !isTranscribing && (
              <ul
                tabIndex={0}
                className="dropdown-content menu bg-base-100 rounded-lg z-[1] w-64 p-2 shadow"
              >
                <li>
                  <button
                    onClick={() => startRecording("enhanced-insert")}
                    className="flex items-center px-4 py-2 hover:bg-gray-100"
                  >
                    <SparklesIcon className="size-4 text-blue-500" />
                    Enhanced Dictation
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => startRecording("pure-insert")}
                    className="flex items-center px-4 py-2 hover:bg-gray-100"
                  >
                    <ChatBubbleLeftEllipsisIcon className="size-4" />
                    Pure Dictation
                  </button>
                </li>
              </ul>
            )}
          </div>
          <IconButton
            onClick={() => editor.chain().focus().toggleBold().run()}
            disabled={
              disabled || !editor.can().chain().focus().toggleBold().run()
            }
            isActive={editor.isActive("bold")}
            description="Bold"
          >
            <BoldIcon className={iconBtnStyles} />
          </IconButton>
          <IconButton
            onClick={() => editor.chain().focus().toggleItalic().run()}
            disabled={
              disabled || !editor.can().chain().focus().toggleItalic().run()
            }
            isActive={editor.isActive("italic")}
            description="Italic"
          >
            <ItalicIcon className={iconBtnStyles} />
          </IconButton>
          <IconButton
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            disabled={
              disabled || !editor.can().chain().focus().toggleUnderline().run()
            }
            isActive={editor.isActive("underline")}
            description="Underline"
          >
            <UnderlineIcon className={iconBtnStyles} />
          </IconButton>
          <IconButton
            onClick={() => editor.chain().focus().toggleStrike().run()}
            disabled={
              disabled || !editor.can().chain().focus().toggleStrike().run()
            }
            isActive={editor.isActive("strike")}
            description="Strikethrough"
          >
            <StrikethroughIcon className={iconBtnStyles} />
          </IconButton>
          <Tooltip overlay="Text Color">
            <div className="relative inline-block">
              <IconButton isActive={isTextColorPickerOpen} disabled={disabled}>
                <PencilIcon className={iconBtnStyles} />
              </IconButton>
              <input
                type="color"
                onInput={(e) => {
                  editor
                    .chain()
                    .focus()
                    .setColor((e.target as HTMLInputElement).value)
                    .run();
                }}
                onFocus={() => setIsTextColorPickerOpen(true)}
                onBlur={() => setIsTextColorPickerOpen(false)}
                className="absolute inset-0 opacity-0 cursor-pointer"
              />
            </div>
          </Tooltip>
          <Tooltip overlay="Highlight color">
            <div className="relative inline-block">
              <IconButton
                isActive={isHighlightColorPickerOpen}
                disabled={disabled}
              >
                <PaintBrushIcon className={iconBtnStyles} />
              </IconButton>
              <input
                type="color"
                onInput={(e) => {
                  editor
                    .chain()
                    .focus()
                    .setHighlight({
                      color: (e.target as HTMLInputElement).value,
                    })
                    .run();
                }}
                onFocus={() => setIsHighlightColorPickerOpen(true)}
                onBlur={() => setIsHighlightColorPickerOpen(false)}
                className="absolute inset-0 opacity-0 cursor-pointer"
              />
            </div>
          </Tooltip>
          <IconButton
            onClick={() => editor.chain().focus().toggleBlockquote().run()}
            disabled={
              disabled || !editor.can().chain().focus().toggleBlockquote().run()
            }
            isActive={editor.isActive("blockquote")}
            description="Blockquote"
          >
            <ChatBubbleBottomCenterTextIcon className={iconBtnStyles} />
          </IconButton>
          <IconButton
            onClick={() => editor.chain().focus().setHorizontalRule().run()}
            disabled={
              disabled ||
              !editor.can().chain().focus().setHorizontalRule().run()
            }
            description="Horizontal line"
          >
            <MinusIcon className={iconBtnStyles} />
          </IconButton>
          <IconButton
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            disabled={
              disabled || !editor.can().chain().focus().toggleBulletList().run()
            }
            isActive={editor.isActive("bulletList")}
            description="Bullet list"
          >
            <ListBulletIcon className={iconBtnStyles} />
          </IconButton>
          <IconButton
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            disabled={
              disabled ||
              !editor.can().chain().focus().toggleOrderedList().run()
            }
            isActive={editor.isActive("orderedList")}
            description="Ordered list"
          >
            <NumberedListIcon className={iconBtnStyles} />
          </IconButton>
          <IconButton
            onClick={() => editor.chain().focus().undo().run()}
            disabled={disabled || !editor.can().chain().focus().undo().run()}
            description="Undo"
          >
            <ArrowUturnLeftIcon className={iconBtnStyles} />
          </IconButton>
          <IconButton
            onClick={() => editor.chain().focus().redo().run()}
            disabled={disabled || !editor.can().chain().focus().redo().run()}
            description="Redo"
          >
            <ArrowUturnRightIcon className={iconBtnStyles} />
          </IconButton>
        </div>
        <div className="flex justify-end">
          <div className="dropdown dropdown-end">
            <Button
              tabIndex={0}
              className={cn(
                "fixed md:relative bottom-0 left-1/2 -translate-x-1/2 md:translate-x-0 md:left-auto md:bottom-auto",
                isMobile && "!w-full rounded-none"
              )}
              variant="primary"
              size="sm"
              disabled={disabled}
            >
              <div className="flex items-center">
                <ArrowUpOnSquareIcon className="size-6 mr-1" />
                <p>Export</p>
              </div>
            </Button>
            <ul
              tabIndex={0}
              className="dropdown-content menu bg-base-100 rounded-lg z-[1] w-52 p-2 shadow mt-2"
            >
              <li>
                <p
                  onClick={() => dialogRef.current?.showModal()}
                  className="hover:bg-gray-100"
                >
                  <DocumentDuplicateIcon className="size-4" />
                  Copy to Clipboard
                </p>
              </li>
              <li onClick={() => emailDialogRef.current?.showModal()}>
                <p className="hover:bg-gray-100">
                  <ChatBubbleBottomCenterTextIcon className="size-4" />
                  E-mail
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {subscriptionStatus === SubscriptionStatusType.enum.trialing && (
        <div className="p-2 text-blue-500 underline text-center border-t-2">
          <Link to="/subscription">
            <>{getTimeRemaining()}</>
          </Link>
        </div>
      )}
      <dialog ref={dialogRef} className="modal">
        <div className="modal-box prose w-11/12 md:w-5/12 p-8">
          <h2>Review and acknowledge</h2>
          <p>
            I affirm that this report, generated with the assistance of Police
            Narratives Al, has been thoroughly reviewed and edited by me to
            ensure its accuracy in representing my recollection of the reported
            events. I acknowledge the potential legal implications of this
            statement and, if required, am prepared to testify to its accuracy.
          </p>
          <form method="dialog">
            <button className="btn absolute top-4 right-4 z-10 border-0 shadow-none">
              <XMarkIcon className="size-6" />
            </button>
            <Button
              onClick={handleCopyToClipboard}
              className="btn-primary w-full"
              variant="primary"
            >
              Agree and copy to clipboard
            </Button>
          </form>
        </div>
      </dialog>
      <dialog ref={emailDialogRef} className="modal">
        <div className="modal-box max-w-96">
          <h3 className="font-bold text-lg mt-2">Send Email</h3>
          <div className="pt-4">
            <Input
              type="email"
              value={emailValue}
              onChange={(e) => {
                setEmailValue(e.target.value);
                setEmailError("");
              }}
              placeholder="Enter email address"
              className={`!w-full !max-w-full ${
                emailError ? "border-red-500" : ""
              }`}
            />
            {emailError && (
              <p className="mt-2 text-xs text-red-500">{emailError}</p>
            )}
          </div>
          <div className="modal-action">
            <Button
              variant="outline"
              onClick={() => {
                emailDialogRef.current?.close();
                setEmailValue("");
                setEmailError("");
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleEmailExport}
              disabled={isLoading || !emailValue}
              variant="primary"
            >
              {isLoading ? "Sending..." : "Send"}
            </Button>
          </div>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button onClick={() => emailDialogRef.current?.close()}>close</button>
        </form>
      </dialog>
    </div>
  );
};

export default EditorMenuBar;

import { XCircleIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import cn from "classnames";
import { ChangeEvent, KeyboardEvent, useRef, useState } from "react";
import Button from "../common/Button";

interface EmailInviteInputProps {
  maxSeats?: number;
  onInvite?: (emails: string[]) => void;
  className?: string;
  placeholder?: string;
  onSuccess?: (results: any[]) => void;
  onError?: (error: string) => void;
}

const EmailInviteInput: React.FC<EmailInviteInputProps> = ({
  maxSeats = Infinity,
  onInvite,
  onSuccess,
  onError,
  className = "",
  placeholder = "Enter email addresses...",
}) => {
  const [emails, setEmails] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const addEmail = (email: string) => {
    const trimmedEmail = email.trim();

    if (!trimmedEmail) return;

    // Check if we've reached the max limit
    if (emails.length >= maxSeats) {
      setError(`You can only invite a maximum of ${maxSeats} people`);
      return;
    }

    // Check if email is valid
    if (!validateEmail(trimmedEmail)) {
      setError(`"${trimmedEmail}" is not a valid email address`);
      return;
    }

    // Check if email already exists
    if (emails.includes(trimmedEmail)) {
      setError(`"${trimmedEmail}" has already been added`);
      return;
    }

    setEmails([...emails, trimmedEmail]);
    setInputValue("");
    setError(null);
  };

  const removeEmail = (indexToRemove: number) => {
    setEmails(emails.filter((_, index) => index !== indexToRemove));
    setError(null);
    inputRef.current?.focus();
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // Handle comma, tab, enter, and space as delimiters
    if (["Enter", "Tab", " ", ","].includes(e.key)) {
      if (inputValue) {
        e.preventDefault();
        addEmail(inputValue);
      }
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    // If the last character is a comma, process the email
    if (value.endsWith(",")) {
      addEmail(value.slice(0, -1));
    } else {
      setInputValue(value);
      if (error) setError(null);
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text");
    const pastedEmails = pasteData.split(/[\s,]+/);

    const validEmails = pastedEmails
      .filter((email) => email.trim() && validateEmail(email.trim()))
      .filter((email) => !emails.includes(email.trim()));

    if (emails.length + validEmails.length > maxSeats) {
      setError(`You can only invite a maximum of ${maxSeats} people`);
      // Add as many as we can up to the limit
      const availableSlots = maxSeats - emails.length;
      if (availableSlots > 0) {
        setEmails([...emails, ...validEmails.slice(0, availableSlots)]);
      }
    } else {
      setEmails([...emails, ...validEmails]);
    }
  };

  const handleInvite = async () => {
    if (emails.length === 0) {
      setError("Please add at least one email address");
      return;
    }

    setIsSubmitting(true);
    try {
      const { data } = await axios.post(
        "/api/agency/invite",
        { emails },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      // Clear the input on success
      setEmails([]);
      setInputValue("");
      setError(null);

      // Call both success handlers
      onSuccess?.(data.results);
      onInvite?.(emails);
    } catch (err) {
      const message = axios.isAxiosError(err)
        ? err.response?.data?.error || "Failed to send invitations"
        : "Failed to send invitations";
      setError(message);
      onError?.(message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={`w-full ${className}`}>
      <div className="form-control w-full">
        <div
          className={cn(
            "input input-bordered flex flex-wrap gap-2 items-start py-2 min-h-[3rem] focus-within:outline-none focus-within:ring-0 focus-within:border-[1px] focus-within:border-gray-500 border-gray-200 rounded-xl bg-gray-50",
            error ? "input-error" : "",
            emails.length > 0 ? "" : "px-6",
            emails.length >= maxSeats ? "opacity-60" : "",
            "h-auto overflow-y-auto"
          )}
        >
          {/* Email Pills */}
          {emails.map((email, index) => (
            <div
              key={index}
              className="badge badge-primary gap-1 px-3 py-4 bg-blue-50 text-blue-500 font-semibold border-0 my-1"
            >
              <span>{email}</span>
              <XCircleIcon
                className="w-5 h-5 cursor-pointer text-gray-800 mr-[-4px]"
                onClick={() => removeEmail(index)}
              />
            </div>
          ))}

          {/* Input */}
          {emails.length < maxSeats && (
            <input
              ref={inputRef}
              type="text"
              value={inputValue}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onPaste={handlePaste}
              placeholder={emails.length === 0 ? placeholder : ""}
              className="flex-grow border-none focus:outline-none bg-transparent min-w-[8rem] py-2"
              aria-label="Email address input"
            />
          )}
        </div>

        {/* Error message */}
        {error && <p className="text-error text-xs mt-1">{error}</p>}

        {/* Seats counter */}
        {maxSeats < Infinity && (
          <div className="text-xs text-gray-500 mt-1 text-end">
            {emails.length} of {maxSeats} seats used
          </div>
        )}
      </div>

      {/* Invite button */}
      <div className="flex justify-end mt-4">
        <Button
          variant="primary"
          onClick={handleInvite}
          disabled={emails.length === 0 || isSubmitting}
        >
          {isSubmitting ? "Sending..." : "Invite"}
        </Button>
      </div>
    </div>
  );
};

export default EmailInviteInput;

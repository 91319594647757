import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useLayoutEffect,
  useCallback,
} from "react";
import { EditorState } from "prosemirror-state";
import {
  ChevronRightIcon,
  SparklesIcon,
  ClipboardDocumentListIcon,
} from "@heroicons/react/24/outline";
import EditorMenuBar from "./Editor/EditorMenuBar";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import Underline from "@tiptap/extension-underline";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import Highlight from "@tiptap/extension-highlight";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import IndentHandler from "./Editor/IndentHandler";
import Suggestions from "./Editor/Suggestions";
import Snippets from "./Editor/Snippets";
import AudioPlayer from "./common/AudioPlayer";
import cn from "classnames";
import { CaseScore, Suggestion } from "../db/schema";
import Button from "./common/Button";
import { useIsMobile } from "../hooks/useIsMobile";
import FloatingMenu from "./Editor/FloatingMenu";

interface TiptapEditorProps {
  onChange: (data: { content: { data: string }; caseId?: string }) => void;
  caseId: string | undefined;
  initialContent: string;
  caseSuggestions?: Suggestion[];
  caseScore?: CaseScore;
  caseAudioUrl?: string;
  isReadOnly?: boolean;
}

function resetEditorContent(editor: any, newContent: string) {
  editor.commands.setContent(newContent);

  // Clear the history by creating a new state
  const newEditorState = EditorState.create({
    doc: editor.state.doc,
    plugins: editor.state.plugins,
    schema: editor.state.schema,
  });
  editor.view.updateState(newEditorState);
}

const TiptapEditor: React.FC<TiptapEditorProps> = ({
  onChange,
  caseId,
  initialContent,
  caseSuggestions,
  caseScore,
  caseAudioUrl,
  isReadOnly,
}) => {
  const isMobile = useIsMobile();
  const [minimized, setMinimized] = useState<boolean>(isMobile);
  const [isTranscribing, setIsTranscribing] = useState(false);

  useEffect(() => {
    setMinimized(isMobile);
  }, [isMobile]);

  const [activeComponent, setActiveComponent] = useState<
    "suggestions" | "snippets"
  >("suggestions");
  const isUserEditing = useRef(false);
  const lastSavedContent = useRef(initialContent);

  const extensions = useMemo(
    () => [
      Color,
      Highlight.configure({ multicolor: true }),
      IndentHandler,
      Placeholder.configure({
        placeholder: ({ node }) => {
          if (node.type.name === "heading" && node.attrs.level === 1) {
            return "Case Title";
          }
          return "Start writing your case narrative here";
        },
      }),
      StarterKit,
      TextStyle,
      Table.configure({
        resizable: true,
        lastColumnResizable: true,
        allowTableNodeSelection: true,
      }),
      TableRow.extend({
        content: "(tableHeader)*",
      }),
      TableHeader,
      TableCell,
      Underline,
    ],
    []
  );

  const handleUpdate = useCallback(
    (editor) => {
      const html = editor.getHTML();
      if (html !== lastSavedContent.current) {
        // Don't update the editor content, just save it
        onChange({
          content: { data: html },
          caseId,
        });
        lastSavedContent.current = html;
      }
    },
    [onChange, caseId]
  );

  const editor = useEditor({
    extensions,
    content: initialContent,
    editorProps: {
      attributes: {
        class: "prose max-w-none mt-8 pb-32 leading-[1.5]",
      },
    },
    onUpdate: ({ editor }) => {
      if (isUserEditing.current) {
        handleUpdate(editor);
      }
    },
    onFocus: () => {
      isUserEditing.current = true;
    },
    onBlur: () => {
      isUserEditing.current = false;
    },
  });

  useLayoutEffect(() => {
    if (editor) {
      if (!initialContent) {
        // Only reset if editor is completely empty
        if (editor.getHTML() === "") {
          editor.commands.clearContent();
          editor.commands.setNode("heading", { level: 1 });
        }
      } else if (
        initialContent !== editor.getHTML() &&
        !isUserEditing.current
      ) {
        // Only reset content if it's different AND user is not currently editing
        resetEditorContent(editor, initialContent);
      }
      lastSavedContent.current = editor.getHTML();
    }
  }, [editor, initialContent]);

  useEffect(() => {
    if (editor) {
      editor.setEditable(!isReadOnly);
    }
  }, [editor, isReadOnly]);

  useEffect(() => {
    return () => {
      if (editor) {
        editor.destroy();
      }
    };
  }, [editor]);

  const insertAtTop = (editor, content) => {
    // Find the first heading node
    let inserted = false;
    editor.state.doc.descendants((node, pos) => {
      if (!inserted && node.type.name === "heading") {
        // Calculate position after the heading
        const insertPos = pos + node.nodeSize;
        editor
          .chain()
          .focus()
          .insertContentAt(insertPos, `<p>${content}</p>\n`)
          .run();
        inserted = true;
        return false; // Stop traversing
      }
    });

    // If no heading found, insert at start
    if (!inserted) {
      editor.chain().focus().insertContentAt(0, `<p>${content}</p>\n`).run();
    }
  };

  const insertAtCursor = (editor, content) => {
    editor.commands.insertContent(`<p>${content}</p>`);
  };

  const insertAtEnd = (editor, content) => {
    if (editor) {
      // Move cursor to end of document
      editor.commands.setTextSelection(editor.state.doc.content.size);
      // Add newline before new content if there isn't one
      const needsNewline = !editor.state.doc.textContent.endsWith("\n");
      editor.commands.insertContent(
        (needsNewline ? "\n" : "") + `<p>${content}</p>`
      );
    }
  };

  const handleInsertContent = (
    content: string,
    mode: "cursor" | "top" | "end" = "cursor"
  ) => {
    if (editor) {
      switch (mode) {
        case "top":
          insertAtTop(editor, content);
          break;
        case "end":
          insertAtEnd(editor, content);
          break;
        case "cursor":
        default:
          insertAtCursor(editor, content);
      }
      handleUpdate(editor);
    }
  };

  const SidebarNav = () => (
    <div className="flex overflow-x-auto border-b-2 border-gray-200 sticky top-0 space-x-1 p-1 bg-gray-100 z-10">
      <Button
        variant={activeComponent === "suggestions" ? "secondary" : "clean"}
        size="xs"
        onClick={() => setActiveComponent("suggestions")}
        className="animate-none"
      >
        Suggestions
      </Button>
      <Button
        variant={activeComponent === "snippets" ? "secondary" : "clean"}
        size="xs"
        onClick={() => setActiveComponent("snippets")}
        className="animate-none"
      >
        Snippets
      </Button>
    </div>
  );

  return (
    <>
      <EditorMenuBar
        editor={editor}
        disabled={isReadOnly}
        caseId={caseId}
        score={caseScore?.score || null}
        onChange={onChange}
        onTranscribeStateChange={setIsTranscribing}
      />
      <div className="flex h-screen relative">
        {isTranscribing && (
          <div className="absolute inset-0 bg-white/70 z-50 pointer-events-none"></div>
        )}
        <div
          className={cn(
            minimized ? "w-full" : "w-3/4 3xl:w-5/6",
            "overflow-y-auto md:border-r-2 transition-all duration-300 ease-in-out flex-grow bg-white"
          )}
        >
          <div className="flex flex-col h-full">
            <div className="flex-1 bg-white flex justify-center">
              <div className="flex-1 p-6 flex flex-col px-12 md:px-24 max-w-6xl w-full h-[120vh] mt-20 md:mt-0">
                <div className="flex-grow">
                  <div className="editor [&_.ProseMirror_ul_>_li_>_p]:mb-0 [&_.ProseMirror_ul_>_li_>_p]:mt-0 [&_.ProseMirror_ol_>_li_>_p]:mb-0 [&_.ProseMirror_ol_>_li_>_p]:mt-0">
                    {editor && <FloatingMenu editor={editor} />}
                    <EditorContent
                      editor={editor}
                      className="max-w-none mt-8 [&_.ProseMirror_h1]:font-bold [&_.ProseMirror_h1]:text-gray-900"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!isReadOnly && (
          <>
            <div
              className={cn(
                "flex absolute z-20 h-48 transition-all duration-300 ease-in-out cursor-pointer",
                minimized
                  ? "right-[-20px] hover:right-0"
                  : "right-0 md:right-[25%] 3xl:right-[16.666%]",
                "top-8"
              )}
              onClick={() => setMinimized(!minimized)}
            >
              <div
                className={cn(
                  "absolute right-3 rounded-l-3xl hover:rounded-full top-10 md:top-[-12px] bottom-0 z-50 bg-gray-100 h-12 w-12 corner transition-all duration-250 ease-in-out",
                  !minimized &&
                    "!rounded-full border-2 md:border-0 border-gray-200"
                )}
              >
                {minimized ? (
                  activeComponent === "suggestions" ? (
                    <SparklesIcon className="size-6 text-blue-500 ml-[9px] mt-3" />
                  ) : (
                    <ClipboardDocumentListIcon className="size-6 text-blue-500 ml-[9px] mt-3" />
                  )
                ) : (
                  <ChevronRightIcon className="size-6 text-blue-500 ml-[12px] mt-[12px]" />
                )}
              </div>
            </div>
            <div
              className={cn(
                "transition-opacity md:transition-all md:duration-300 md:ease-in-out mt-16 md:mt-0",
                "overflow-y-auto mb-[56px]",
                "absolute md:relative bg-gray-100",
                minimized
                  ? "w-0 opacity-0 md:invisible"
                  : "w-full md:w-1/4 3xl:w-1/6 opacity-100 md:visible"
              )}
            >
              {caseAudioUrl && <AudioPlayer url={caseAudioUrl} />}
              <SidebarNav />
              <div
                className={cn(
                  "px-4 py-0 md:py-4 flex flex-col h-[110vh]",
                  activeComponent === "suggestions"
                    ? "md:h-[120vh]"
                    : "md:h-auto"
                )}
              >
                {activeComponent === "suggestions" && (
                  <Suggestions
                    caseSuggestions={caseSuggestions}
                    caseScore={caseScore}
                    caseId={caseId}
                    editorContent={editor
                      ?.getText()
                      .replace(/\n{3,}/g, "\n\n")
                      .trim()}
                  />
                )}
                {activeComponent === "snippets" && (
                  <Snippets onInsertContent={handleInsertContent} />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

interface EditorProps {
  caseContent?: { data: string };
  onChange?: ({
    content,
    caseId,
  }: {
    content: { data: string };
    caseId?: string;
  }) => void;
  caseId?: string;
  hasCases?: boolean;
  page: string;
  caseSuggestions?: Suggestion[];
  caseScore?: CaseScore;
  caseAudioUrl?: string;
  isProcessing?: boolean;
}

function Editor({
  caseContent,
  onChange,
  hasCases,
  caseId,
  page,
  caseSuggestions,
  caseScore,
  caseAudioUrl,
  isProcessing,
}: EditorProps) {
  const initialContent = caseContent?.data || "";
  const isReadOnly = page === "trash" || isProcessing;
  return (
    <>
      {hasCases ? (
        <div className="flex flex-col h-full overflow-hidden">
          <TiptapEditor
            onChange={onChange!}
            caseId={caseId}
            initialContent={initialContent}
            caseSuggestions={caseSuggestions}
            caseScore={caseScore}
            caseAudioUrl={caseAudioUrl}
            isReadOnly={isReadOnly}
          />
        </div>
      ) : (
        <div className="flex-1 flex items-center justify-center bg-white pt-6">
          <p className="text-xl text-gray-500">
            Create a new case to start reporting
          </p>
        </div>
      )}
    </>
  );
}

export default Editor;

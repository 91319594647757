import React from "react";
import { X } from "lucide-react";

interface EnhanceCompareModalProps {
  isOpen: boolean;
  onClose: () => void;
  originalContent: string;
  enhancedContent: string;
  onAccept: () => void;
  onDiscard: () => void;
}

const EnhanceCompareModal = ({
  isOpen,
  onClose,
  originalContent,
  enhancedContent,
  onAccept,
  onDiscard,
}: EnhanceCompareModalProps) => {
  if (!isOpen) return null;

  const formatContent = (content: string) => {
    return content
      .split("\n")
      .map((para) => para.trim())
      .filter((para) => para.length > 0)
      .map((para) => `<p>${para}</p>`)
      .join("");
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-4xl max-h-[90vh] flex flex-col border border-gray-200">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-lg font-semibold">Review Enhancement</h2>
          <button
            onClick={onClose}
            className="p-1 rounded-full hover:bg-gray-100"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="flex-1 overflow-auto p-4 grid grid-cols-2 gap-6">
          <div className="space-y-2">
            <h3 className="font-medium text-gray-700">Original Text</h3>
            <div
              className="p-3 border border-gray-200 rounded-md bg-gray-50 flex flex-col gap-4"
              dangerouslySetInnerHTML={{
                __html: formatContent(originalContent),
              }}
            />
          </div>

          <div className="space-y-2">
            <h3 className="font-medium text-emerald-700">Enhanced Text</h3>
            <div
              className="p-3 border border-emerald-200 rounded-md bg-emerald-50 flex flex-col gap-4"
              dangerouslySetInnerHTML={{
                __html: formatContent(enhancedContent),
              }}
            />
          </div>
        </div>

        <div className="p-4 border-t flex justify-end gap-3">
          <button
            onClick={onDiscard}
            className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
          >
            Discard
          </button>
          <button
            onClick={onAccept}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Accept Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default EnhanceCompareModal;

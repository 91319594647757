// src/context/AuthContext.tsx
import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { SubscriptionStatus, SubscriptionStatusType, User } from "../db/schema";

interface AuthContextType {
  isAuthenticated: boolean;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  loading: boolean;
  subscriptionStatus: (typeof SubscriptionStatus.enumValues)[number] | null;
  subscriptionCurrentPeriodEnd: Date | null;
  setSubscriptionStatus: React.Dispatch<
    React.SetStateAction<(typeof SubscriptionStatus.enumValues)[number] | null>
  >;
  setSubscriptionCurrentPeriodEnd: React.Dispatch<
    React.SetStateAction<Date | null>
  >;
  refreshSubscriptionStatus: () => Promise<
    (typeof SubscriptionStatus.enumValues)[number] | null
  >;
  subscriptionPlatform: string | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [subscriptionStatus, setSubscriptionStatus] = useState<
    (typeof SubscriptionStatus.enumValues)[number] | null
  >(null);
  const [subscriptionCurrentPeriodEnd, setSubscriptionCurrentPeriodEnd] =
    useState<Date | null>(null);
  const [subscriptionPlatform, setSubscriptionPlatform] = useState<
    string | null
  >(null);

  const refreshSubscriptionStatus = async (): Promise<
    (typeof SubscriptionStatus.enumValues)[number] | null
  > => {
    try {
      const subscriptionResponse = await axios.get("/api/subscription/status");
      setSubscriptionStatus(subscriptionResponse.data.status);
      setSubscriptionCurrentPeriodEnd(
        subscriptionResponse.data.currentPeriodEnd
      );
      return subscriptionResponse.data.status;
    } catch (error) {
      console.error("Error refreshing subscription status:", error);
      return null;
    }
  };

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await axios.get("/api/auth/user");
        if (response.data.user) {
          setIsAuthenticated(true);
          setUser(response.data.user);

          // Fetch subscription status
          const subscriptionResponse = await axios.get(
            "/api/subscription/status"
          );
          console.log("subscriptionResponse");
          console.log(subscriptionResponse);
          setSubscriptionStatus(subscriptionResponse.data.status);
          setSubscriptionCurrentPeriodEnd(
            subscriptionResponse.data.currentPeriodEnd
          );
          setSubscriptionPlatform(subscriptionResponse.data.platform);

          // Only redirect if not on subscription or trial-related pages
          const isSubscriptionPage =
            window.location.pathname.includes("/subscription");
          const isConfirmationPage =
            window.location.pathname.includes("/confirmation");

          if (!isSubscriptionPage && !isConfirmationPage) {
            if (!subscriptionResponse.data.status) {
              window.location.href = "/subscription";
            }
            // Redirect if subscription is incomplete or expired
            else if (
              subscriptionResponse.data.status !==
                SubscriptionStatusType.enum.trialing &&
              subscriptionResponse.data.status !==
                SubscriptionStatusType.enum.active
            ) {
              window.location.href = "/subscription";
            }
          }
        }
      } catch (error) {
        setIsAuthenticated(false);
        setUser(null);
        setSubscriptionStatus(null);
      } finally {
        setLoading(false);
      }
    };

    checkAuthStatus();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        user,
        setUser,
        loading,
        subscriptionStatus,
        setSubscriptionStatus,
        subscriptionCurrentPeriodEnd,
        setSubscriptionCurrentPeriodEnd,
        refreshSubscriptionStatus,
        subscriptionPlatform,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

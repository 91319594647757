import { Navigate, Outlet } from "react-router-dom";
import { useAgencyAuth } from "../context/AgencyAuthContext";
import Loading from "./common/Loading";

const AgencyProtectedRoute = () => {
  const { isAuthenticated, loading } = useAgencyAuth();

  if (loading) {
    return <Loading />;
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/agency/login" />;
};

export default AgencyProtectedRoute;

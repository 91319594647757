import { loadStripe } from "@stripe/stripe-js";
import axios, { AxiosError } from "axios";
import { startCase } from "lodash";
import { FormEvent, useEffect, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import bgImg from "../pnai-bg.jpg";
import { ReactComponent as Logo } from "../police-narratives-ai.svg";
import Button from "./common/Button";
import Input from "./common/Input";
import Loading from "./common/Loading";

// Initialize Stripe
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY ||
    "pk_live_51PydONG4bf6K0RLmdfEJzwpzVpDCNNdt0HjC5sn5QokyLJij6TflI306Xoi69SSqHORZ9cPnt3xw2leQx9rrYb0B00nka5yr7n"
);

// Add these utility functions at the top level
const getPriceId = (searchParams: URLSearchParams) =>
  searchParams.get("priceId");
const appendSearchParams = (path: string, searchParams: URLSearchParams) =>
  `${path}${searchParams.size ? `?${searchParams.toString()}` : ""}`;

function App() {
  const [name, setName] = useState<string>("");
  const [badgeID, setBadgeID] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [department, setDepartment] = useState<string>("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [errorState, setErrorState] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated, setIsAuthenticated, setUser } = useAuth();
  const [searchParams] = useSearchParams();
  const [isInvitation, setIsInvitation] = useState(false);

  useEffect(() => {
    // Check for invitation parameters
    const emailParam = searchParams.get("email");
    const tokenParam = searchParams.get("token");
    const departmentParam = searchParams.get("department");

    if (emailParam && departmentParam && tokenParam) {
      setIsInvitation(true);
      setEmail(emailParam.trim());
      setDepartment(startCase(departmentParam));
    }
  }, [searchParams]);

  useEffect(() => {
    if (isAuthenticated && !getPriceId(searchParams)) {
      setLoading(true);
      navigate("/subscription");
    }
  }, [navigate, isAuthenticated, searchParams]);

  const validateForm = () => {
    let newErrors: {
      email?: string;
      password?: string;
      name?: string;
      badgeID?: string;
      department?: string;
    } = {};

    // Name validation
    if (!name) {
      newErrors.name = "Name is required";
    } else if (!/^[a-zA-Z\s'-]+$/.test(name)) {
      newErrors.name = "Name is invalid";
    }

    // Badge validation
    if (!badgeID) {
      newErrors.badgeID = "Badge ID is required";
    } else if (badgeID.trim() === "") {
      newErrors.badgeID = "Badge ID is invalid";
    }

    // Department validation
    if (!department) {
      newErrors.department = "Department is required";
    }

    // Email validation
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email is invalid";
    }

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{12,}$/;

    // Password validation
    if (!password) {
      newErrors.password = "Password is required";
    } else if (!passwordRegex.test(password)) {
      newErrors.password =
        "Password must be at least 12 characters long and include a combination of uppercase and lowercase letters, numbers, and symbols";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        // First create the user
        const response = await axios.post("/api/auth/signup", {
          name,
          badgeID,
          email,
          password,
          department,
        });

        if (response.data.user) {
          // If this is an agency invitation signup
          const emailParam = searchParams.get("email");
          const tokenParam = searchParams.get("token");
          const departmentParam = searchParams.get("department");

          if (emailParam && departmentParam && tokenParam) {
            try {
              // Accept the invitation with token validation
              const inviteResponse = await axios.post(
                "/api/agency/accept-invitation",
                {
                  email: emailParam.trim(),
                  userId: response.data.user.id,
                  token: tokenParam,
                }
              );

              if (inviteResponse.data.success) {
                setIsAuthenticated(true);
                setUser(inviteResponse.data.user);
                navigate("/cases");
                return;
              } else {
                setErrors({
                  overall: "Invalid or expired invitation link.",
                });
                setLoading(false);
                return;
              }
            } catch (inviteError) {
              console.error("Error accepting invitation:", inviteError);
              setErrors({
                overall: "Invalid or expired invitation link.",
              });
              setLoading(false);
              return;
            }
          } else {
            setIsAuthenticated(true);
            setUser(response.data.user);
          }
        }

        // Rest of your existing code...
        const priceId = getPriceId(searchParams);
        if (priceId) {
          const stripe = await stripePromise;
          if (!stripe) throw new Error("Stripe failed to initialize");

          const checkoutResponse = await axios.post(
            "/api/create-checkout-session",
            { priceId },
            { withCredentials: true }
          );

          await stripe.redirectToCheckout({
            sessionId: checkoutResponse.data.sessionId,
          });
        } else {
          navigate("/subscription");
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setErrorState(true);
          const axiosError = error as AxiosError<{ error: string }>;
          if (axiosError.response) {
            console.error(axiosError.response.data.error);
            setErrors({
              overall:
                axiosError.response.data.error || "An unknown error occurred",
            });
          }
        }
        setLoading(false);
      }
    } else {
      setErrorState(true);
    }
  };

  const handleGoogleSignup = () => {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/api/auth/google`;
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      <div
        className="hidden md:flex relative items-center justify-center bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${bgImg})` }}
      >
        <div className="absolute inset-0 bg-blue-50/70"></div>
        <div className="w-3/5 z-10">
          <Logo className="w-full h-auto" />
        </div>
      </div>
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="bg-white p-8 rounded-xl shadow-md w-full max-w-sm">
          {isInvitation ? (
            <>
              <h1 className="text-3xl mb-2 font-semibold">Accept Invitation</h1>
              <p className="mb-3 text-xs text-gray-500">
                Create an account to join {department} and activate your
                subscription.
              </p>
            </>
          ) : (
            <>
              <h1 className="text-3xl mb-2 font-semibold">Sign up</h1>
              <p className="mb-3 text-xs text-gray-500">
                This information is private and only used for reports.
              </p>
            </>
          )}

          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <p className="font-semibold pb-1 text-sm">Name</p>
              <Input
                type="text"
                id="name"
                value={name}
                placeholder="John Doe"
                onChange={(e) => {
                  errorState && setErrorState(false);
                  setName(e.target.value);
                }}
                className={`input w-full ${errorState && "border-red-500"}`}
              />
              {errors.name && (
                <p className="mt-1 text-xs text-red-500">{errors.name}</p>
              )}
            </div>
            <div>
              <p className="font-semibold pb-1 text-sm">Badge ID</p>
              <Input
                type="text"
                id="badgeID"
                value={badgeID}
                placeholder="1314"
                onChange={(e) => {
                  errorState && setErrorState(false);
                  setBadgeID(e.target.value);
                }}
                className={`input w-full ${errorState && "border-red-500"}`}
              />
              {errors.badgeID && (
                <p className="mt-1 text-xs text-red-500">{errors.badgeID}</p>
              )}
            </div>
            <div>
              <p className="font-semibold pb-1 text-sm">Department</p>
              <Input
                type="text"
                id="department"
                value={department}
                placeholder="Los Angeles Police Department"
                onChange={(e) => {
                  errorState && setErrorState(false);
                  setDepartment(e.target.value);
                }}
                disabled={!!searchParams.get("department")}
                className={`input w-full ${errorState && "border-red-500"}`}
              />
              {errors.department && (
                <p className="mt-1 text-xs text-red-500">{errors.department}</p>
              )}
            </div>
            <div>
              <p className="font-semibold pb-1 text-sm">Email</p>
              <Input
                type="email"
                id="email"
                value={email}
                placeholder="Email address"
                onChange={(e) => {
                  errorState && setErrorState(false);
                  setEmail(e.target.value);
                }}
                disabled={!!searchParams.get("email")}
                className={`input w-full ${errorState && "border-red-500"}`}
              />
              {errors.email && (
                <p className="mt-1 text-xs text-red-500">{errors.email}</p>
              )}
            </div>
            <div>
              <p className="font-semibold pb-1 text-sm">Password</p>
              <Input
                type="password"
                id="password"
                value={password}
                placeholder="Password"
                onChange={(e) => {
                  errorState && setErrorState(false);
                  setPassword(e.target.value);
                }}
                className={`input w-full max-w-xs ${
                  errorState && "border-red-500"
                }`}
              />
              {errors.password && errorState && (
                <p className="mt-1 text-xs text-red-500">{errors.password}</p>
              )}
              {errors.overall && errorState && (
                <p className="mt-1 text-xs text-red-500">{errors.overall}</p>
              )}
            </div>
            <div>
              <Button className="w-full" variant="primary" type="submit">
                {isInvitation ? "Accept Invitation" : "Sign up"}
              </Button>
            </div>
          </form>
          {!isInvitation && (
            <>
              <div className="relative my-4">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">Or</span>
                </div>
              </div>

              <Button
                onClick={handleGoogleSignup}
                variant="outline"
                className="w-full mb-2"
              >
                <FcGoogle className="w-5 h-5" />
                <span>Continue with Google</span>
              </Button>
              <div className="flex justify-center">
                <p className="font-semibold text-sm text-gray-500">
                  Already have an account?{" "}
                  <Link to={appendSearchParams("/login", searchParams)}>
                    Login
                  </Link>
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
